import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TextField, Grid, FormGroup, Button } from "@material-ui/core";
import { appConfig } from "../../config/app.config";
import { FormikProps } from "formik";
import { IState } from "./EditForm";
import { IVideo } from "../../interfaces/model.interfaces";

const GamePerformanceStatsForm = ({ props, classes, game }: any) => {
  const useFileHandlers = (
    prefix: string,
    fileInput: React.MutableRefObject<HTMLInputElement>,
    careerFileInput: React.MutableRefObject<HTMLInputElement>
  ) => {
    const onClickShotChart = useCallback(() => {
      if (!fileInput.current) {
        return;
      }

      fileInput.current.click();
    }, [fileInput]);

    const handleChangeShotChart = useCallback(
      async (
        event: ChangeEvent<HTMLInputElement>,
        props: FormikProps<IState>
      ) => {
        if (event.target.files && event.target.files.length > 0) {
          const file: File = event.target.files[0];
          const newFilename = `_${prefix}|game|${game.id}_${file.name}`;
          const newFile = new File([file], newFilename, { type: file.type });
          props.setFieldValue(
            `${prefix}PerformanceStats.shotAreaChart`,
            newFile
          );
        }
      },
      []
    );

    const onClickCareerShotChart = useCallback(() => {
      if (!careerFileInput.current) {
        return;
      }

      careerFileInput.current.click();
    }, [careerFileInput]);

    const handleChangeCareerShotChart = useCallback(
      async (
        event: ChangeEvent<HTMLInputElement>,
        props: FormikProps<IState>
      ) => {
        if (event.target.files && event.target.files.length > 0) {
          const file: File = event.target.files[0];
          const newFilename = `_${prefix}|career|${game.id}_${file.name}`;
          const newFile = new File([file], newFilename, { type: file.type });
          props.setFieldValue(
            `${prefix}PerformanceStats.careerShotAreaChart`,
            newFile
          );
        }
      },
      []
    );

    return {
      onClickShotChart,
      handleChangeShotChart,
      onClickCareerShotChart,
      handleChangeCareerShotChart,
    };
  };

  const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const careerFileInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const {
    onClickShotChart,
    handleChangeShotChart,
    onClickCareerShotChart,
    handleChangeCareerShotChart,
  } = useFileHandlers("author", fileInput, careerFileInput);

  const opponentFileInput =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const opponentCareerFileInput =
    useRef() as React.MutableRefObject<HTMLInputElement>;
  const {
    onClickShotChart: onClickOpponentShotChart,
    handleChangeShotChart: handleChangeOpponentShotChart,
    onClickCareerShotChart: onClickOpponentCareerShotChart,
    handleChangeCareerShotChart: handleChangeOpponentCareerShotChart,
  } = useFileHandlers("opponent", opponentFileInput, opponentCareerFileInput);

  const returnTouched = (field: string, touched: any, user = "author") => {
    if (user === "author") {
      return touched.authorPerformanceStats?.[field];
    }
    if (user === "opponent") {
      return touched.opponentPerformanceStats?.[field];
    }
  };

  const returnError = (field: string, errors: any, user = "author") => {
    let error;

    if (user === "author") {
      error = errors.authorPerformanceStats?.[field];
    }
    if (user === "opponent") {
      error = errors.opponentPerformanceStats?.[field];
    }

    if (error && error.includes(".")) {
      return error.split(".").pop();
    }

    return error;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const authorVideo = game?.videos?.find(
    (video: IVideo) => video?.user?.id === game.author.id
  );
  const opponentVideo = game?.videos?.find(
    (video: IVideo) => video?.user?.id === game.opponent.id
  );

  return (
    <>
      <div className={classes.delimiter} />
      <Grid item md={6} xs={12}>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="FG%"
            name="authorPerformanceStats.fg"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.fg}
            error={
              (props.submitCount > 0 || returnTouched("fg", props.touched)) &&
              Boolean(returnError("fg", props.errors))
            }
            helperText={
              (props.submitCount > 0 || returnTouched("fg", props.touched)) &&
              returnError("fg", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="3PT%"
            name="authorPerformanceStats.threePT"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.threePT}
            error={
              (props.submitCount > 0 ||
                returnTouched("threePT", props.touched)) &&
              Boolean(returnError("threePT", props.errors))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("threePT", props.touched)) &&
              returnError("threePT", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="FT%"
            name="authorPerformanceStats.ft"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.ft}
            error={
              (props.submitCount > 0 || returnTouched("ft", props.touched)) &&
              Boolean(returnError("ft", props.errors))
            }
            helperText={
              (props.submitCount > 0 || returnTouched("ft", props.touched)) &&
              returnError("ft", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Release Time"
            name="authorPerformanceStats.releaseTime"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.releaseTime}
            error={
              (props.submitCount > 0 ||
                returnTouched("releaseTime", props.touched)) &&
              Boolean(returnError("releaseTime", props.errors))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("releaseTime", props.touched)) &&
              returnError("releaseTime", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Release Angle"
            name="authorPerformanceStats.releaseAngle"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.releaseAngle}
            error={
              (props.submitCount > 0 ||
                returnTouched("releaseAngle", props.touched)) &&
              Boolean(returnError("releaseAngle", props.errors))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("releaseAngle", props.touched)) &&
              returnError("releaseAngle", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Vertical"
            name="authorPerformanceStats.vertical"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.vertical}
            error={
              (props.submitCount > 0 ||
                returnTouched("vertical", props.touched)) &&
              Boolean(returnError("vertical", props.errors))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("vertical", props.touched)) &&
              returnError("vertical", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Speed"
            name="authorPerformanceStats.speed"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.speed}
            error={
              (props.submitCount > 0 ||
                returnTouched("speed", props.touched)) &&
              Boolean(returnError("speed", props.errors))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("speed", props.touched)) &&
              returnError("speed", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Leg angle"
            name="authorPerformanceStats.legAngle"
            onChange={props.handleChange}
            value={props.values.authorPerformanceStats?.legAngle}
            error={
              (props.submitCount > 0 ||
                returnTouched("legAngle", props.touched)) &&
              Boolean(returnError("legAngle", props.errors))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("legAngle", props.touched)) &&
              returnError("legAngle", props.errors)
            }
            onKeyPress={handleKeyPress}
            disabled={!authorVideo}
          />
        </FormGroup>
        <FormGroup>
          {props.values.authorPerformanceStats?.shotAreaChart &&
            typeof props.values.authorPerformanceStats?.shotAreaChart ===
              "string" && (
              <div>
                <img
                  className={classes.chartImg}
                  src={`${appConfig.apiEndpoint}/${props.values.authorPerformanceStats?.shotAreaChart}`}
                />
              </div>
            )}
          <input
            className={classes.hidden}
            type="file"
            accept="image/jpeg"
            name="shot-chart"
            ref={fileInput}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e) => handleChangeShotChart(e, props)}
          />
          <Button
            size="large"
            color="primary"
            variant="outlined"
            className={classes.uploadChartBtn}
            onClick={onClickShotChart}
            disabled={!authorVideo}
          >
            Upload Shot area chart
          </Button>
          {props.values.authorPerformanceStats?.shotAreaChart && (
            <p className={classes.uploadText}>
              {props.values.authorPerformanceStats.shotAreaChart instanceof File
                ? props.values.authorPerformanceStats?.shotAreaChart.name.slice(
                    13
                  )
                : ""}
            </p>
          )}
        </FormGroup>
        <FormGroup>
          {props.values.authorPerformanceStats?.user?.careerShotAreaChart &&
            !props.values.authorPerformanceStats?.careerShotAreaChart &&
            typeof props.values.authorPerformanceStats?.user
              ?.careerShotAreaChart === "string" && (
              <div>
                <img
                  className={classes.chartImg}
                  src={`${appConfig.apiEndpoint}/${props.values.authorPerformanceStats?.user?.careerShotAreaChart}`}
                />
              </div>
            )}
          <input
            className={classes.hidden}
            type="file"
            accept="image/jpeg"
            name="career-shot-chart"
            ref={careerFileInput}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e) => handleChangeCareerShotChart(e, props)}
          />
          <Button
            size="large"
            color="primary"
            variant="outlined"
            className={classes.uploadChartBtn}
            onClick={onClickCareerShotChart}
            disabled={!authorVideo}
          >
            Upload Career Shot area chart
          </Button>
          {props.values.authorPerformanceStats?.careerShotAreaChart && (
            <p className={classes.uploadText}>
              {props.values.authorPerformanceStats
                .careerShotAreaChart instanceof File
                ? props.values.authorPerformanceStats?.careerShotAreaChart.name.slice(
                    15
                  )
                : ""}
            </p>
          )}
        </FormGroup>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent FG%"
            name="opponentPerformanceStats.fg"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.fg}
            error={
              (props.submitCount > 0 ||
                returnTouched("fg", props.touched, "opponent")) &&
              Boolean(returnError("fg", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("fg", props.touched, "opponent")) &&
              returnError("fg", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent 3PT%"
            name="opponentPerformanceStats.threePT"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.threePT}
            error={
              (props.submitCount > 0 ||
                returnTouched("threePT", props.touched, "opponent")) &&
              Boolean(returnError("threePT", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("threePT", props.touched, "opponent")) &&
              returnError("threePT", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent FT%"
            name="opponentPerformanceStats.ft"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.ft}
            error={
              (props.submitCount > 0 ||
                returnTouched("ft", props.touched, "opponent")) &&
              Boolean(returnError("ft", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("ft", props.touched, "opponent")) &&
              returnError("ft", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent Release Time"
            name="opponentPerformanceStats.releaseTime"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.releaseTime}
            error={
              (props.submitCount > 0 ||
                returnTouched("releaseTime", props.touched, "opponent")) &&
              Boolean(returnError("releaseTime", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("releaseTime", props.touched, "opponent")) &&
              returnError("releaseTime", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent Release Angle"
            name="opponentPerformanceStats.releaseAngle"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.releaseAngle}
            error={
              (props.submitCount > 0 ||
                returnTouched("releaseAngle", props.touched, "opponent")) &&
              Boolean(returnError("releaseAngle", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("releaseAngle", props.touched, "opponent")) &&
              returnError("releaseAngle", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent Vertical"
            name="opponentPerformanceStats.vertical"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.vertical}
            error={
              (props.submitCount > 0 ||
                returnTouched("vertical", props.touched, "opponent")) &&
              Boolean(returnError("vertical", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("vertical", props.touched, "opponent")) &&
              returnError("vertical", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent Speed"
            name="opponentPerformanceStats.speed"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.speed}
            error={
              (props.submitCount > 0 ||
                returnTouched("speed", props.touched, "opponent")) &&
              Boolean(returnError("speed", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("speed", props.touched, "opponent")) &&
              returnError("speed", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            margin="normal"
            label="Opponent Leg angle"
            name="opponentPerformanceStats.legAngle"
            onChange={props.handleChange}
            value={props.values.opponentPerformanceStats?.legAngle}
            error={
              (props.submitCount > 0 ||
                returnTouched("legAngle", props.touched, "opponent")) &&
              Boolean(returnError("legAngle", props.errors, "opponent"))
            }
            helperText={
              (props.submitCount > 0 ||
                returnTouched("legAngle", props.touched, "opponent")) &&
              returnError("legAngle", props.errors, "opponent")
            }
            onKeyPress={handleKeyPress}
            disabled={!opponentVideo}
          />
        </FormGroup>
        <FormGroup>
          {props.values.opponentPerformanceStats?.shotAreaChart &&
            typeof props.values.opponentPerformanceStats?.shotAreaChart ===
              "string" && (
              <div>
                <img
                  className={classes.chartImg}
                  src={`${appConfig.apiEndpoint}/${props.values.opponentPerformanceStats?.shotAreaChart}`}
                />
              </div>
            )}
          <input
            className={classes.hidden}
            type="file"
            accept="image/jpeg"
            name="opponent-shot-chart"
            ref={opponentFileInput}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e) => handleChangeOpponentShotChart(e, props)}
          />
          <Button
            size="large"
            color="primary"
            variant="outlined"
            className={classes.uploadChartBtn}
            onClick={onClickOpponentShotChart}
            disabled={!opponentVideo}
          >
            Upload Opponent Shot area chart
          </Button>
          {props.values.opponentPerformanceStats?.shotAreaChart && (
            <p className={classes.uploadText}>
              {props.values.opponentPerformanceStats.shotAreaChart instanceof
              File
                ? props.values.opponentPerformanceStats?.shotAreaChart.name.slice(
                    15
                  )
                : ""}
            </p>
          )}
        </FormGroup>
        <FormGroup>
          {props.values.opponentPerformanceStats?.user?.careerShotAreaChart &&
            !props.values.opponentPerformanceStats?.careerShotAreaChart &&
            typeof props.values.opponentPerformanceStats?.user
              ?.careerShotAreaChart === "string" && (
              <div>
                <img
                  className={classes.chartImg}
                  src={`${appConfig.apiEndpoint}/${props.values.opponentPerformanceStats?.user?.careerShotAreaChart}`}
                />
              </div>
            )}
          <input
            className={classes.hidden}
            type="file"
            accept="image/jpeg"
            name="opponent-career-shot-chart"
            ref={opponentCareerFileInput}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e) => handleChangeOpponentCareerShotChart(e, props)}
          />
          <Button
            size="large"
            color="primary"
            variant="outlined"
            className={classes.uploadChartBtn}
            onClick={onClickOpponentCareerShotChart}
            disabled={!opponentVideo}
          >
            Upload Opponent Career Shot area chart
          </Button>
          {props.values.opponentPerformanceStats?.careerShotAreaChart && (
            <p className={classes.uploadText}>
              {props.values.opponentPerformanceStats
                .careerShotAreaChart instanceof File
                ? props.values.opponentPerformanceStats?.careerShotAreaChart.name.slice(
                    17
                  )
                : ""}
            </p>
          )}
        </FormGroup>
      </Grid>
    </>
  );
};

export default GamePerformanceStatsForm;
