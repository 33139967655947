import { ILogin } from './../interfaces/auth.interfaces';
import { UserIdentity } from 'react-admin';
import { appConfig } from './../config/app.config';

export const authProvider = {
    // called when the user attempts to log in
    login: ({ email, password }: ILogin): Promise<void> => {

        const request = new Request(`${appConfig.apiEndpoint}/auth/sign-in`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(auth => {

                if (auth?.role !== 'admin') {
                    throw new Error();
                }

                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
    // called when the user clicks on the logout button
    logout: (): Promise<void> => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (data: { status: number }): Promise<void> => {

        const status = data.status;

        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: (): Promise<void> => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: (): Promise<void> => Promise.resolve(),
    getIdentity: (): Promise<UserIdentity> => {
        try {

            const auth = localStorage.getItem('auth');

            if (!auth) {
                return Promise.reject();
            }

            const data = JSON.parse(auth);

            const identity = {
                id: data.id,
                fullName: data.username,
            } as UserIdentity;

            return Promise.resolve({ ...identity });

        } catch (err: unknown) {
            return Promise.reject(err);
        }
    },
};