import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    ListProps,
} from 'react-admin';

const LevelsFilter = (props: unknown): JSX.Element => (
    <Filter {...props}>
        <TextInput label="Level" source="level" />
        <TextInput label="points" source="points" />
    </Filter>
);

export const LevelList = (props: ListProps): JSX.Element => (
    <List
        {...props}
        title="List of levels"
        filters={<LevelsFilter />}
        bulkActionButtons={false}
    >
        <Datagrid optimized>
            <TextField
                source="level"
            />
            <TextField
                source="points"
            />
        </Datagrid>
    </List>
);