import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useNotify } from 'react-admin';
import { Login } from 'react-admin';
import { Button, TextField, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IForgot } from './../../interfaces/auth.interfaces';
import { Link } from 'react-router-dom';
import { appConfig } from './../../config/app.config';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        display: 'none',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 3),
    },
    back: {
        margin: theme.spacing(1, 0, 3),
    },
    header: {
        margin: theme.spacing(3, 0, 0),
    },
}));

const Forgot: React.FC = () => {

    // const login = useLogin();
    const notify = useNotify();

    const [initialValues] = useState<IForgot>({
        email: '',
    });

    const validationSchema = yup.object().shape({
        email: yup.string()
            .trim()
            .required()
            .email()
            .max(45),
    });

    const handleSubmit = useCallback(({ email }: IForgot) => {

        const request = new Request(`${appConfig.apiEndpoint}/auth/forgot`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                notify('Check you email', 'success');
            })
            .catch(() => {
                notify('Email was not found', 'error');
            });
    }, [notify]);

    const classes = useStyles();

    const formComponent = (
        <React.Fragment>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IForgot>) => (
                        <Container component="main" maxWidth="xs">
                            <h2 className={classes.header}>Forgot password</h2>
                            <form
                                className={classes.form}
                                noValidate
                                onSubmit={props.handleSubmit}
                            >
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email"
                                    name="email"
                                    onChange={props.handleChange}
                                    value={props.values.email}
                                    error={props.touched.email && Boolean(props.errors.email)}
                                    helperText={props.touched.email && props.errors.email}
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Forgot password
                                </Button>
                                <Button
                                    fullWidth
                                    component={Link}
                                    to="/"
                                    size="large"
                                    variant="outlined"
                                    className={classes.back}
                                >
                                    Back
                                </Button>
                            </form>
                        </Container>
                    )
                }
            </Formik>
        </React.Fragment>
    );

    return (
        <Login
            backgroundImage="https://source.unsplash.com/random/1600x900/?basketball"
            // eslint-disable-next-line react/no-children-prop
            children={formComponent}
            classes={classes}
        />
    );
};

export default Forgot;