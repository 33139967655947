import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    ListProps,
    ShowButton
} from 'react-admin';

const VideoFilter = (props: unknown): JSX.Element =>  (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Name" source="name" />
        <TextInput label="Game id" source="game.id" />
    </Filter>
);

export const VideosList = (props: ListProps): JSX.Element =>(
    <List
        {...props}
        title="List of videos"
        filters={<VideoFilter />}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                source="name"
            />
            <TextField
                label="Game id"
                source="game.id"
            />
            <ShowButton />
        </Datagrid>
    </List>
);