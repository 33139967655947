import React, { useCallback } from 'react';
import { SimpleFormProps, useNotify } from 'react-admin';
import { extractErrorMessage, apiRequestProvider } from './../../providers/api-request.provider';
import {
    IProduct,
    IProductForm,
    IMutationError,
} from './../../interfaces/model.interfaces';
import { ProductForm } from './ProductFrom';
import { clearEmptyString } from '../../helpers/data-format.helper';
import { serialize } from 'object-to-formdata';

interface IEditProductProps {
    record: IProduct;
}

export const EditForm: React.FC<SimpleFormProps & IEditProductProps> = (
    props: SimpleFormProps,
) => {

    const { record, history } = props;

    const notify = useNotify();

    const onSuccess = useCallback(() => {
        notify('Product update successful', 'success');
        history.push('/products');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async(values: IProductForm) => {

        const data = clearEmptyString(values);

        const formData = serialize(data);

        await apiRequestProvider({
            url: `/products/${record.id}`,
            method: 'PATCH',
            data: formData,
            onSuccess,
            onFailure,
        });

    }, [record, onSuccess, onFailure]);

    return (
        <div>
            <ProductForm
                product={record}
                header="Edit product"
                handleSubmit={handleSubmit}
            />
        </div>
    );
};