import { IClearEmptyString } from './../interfaces/model.interfaces';

export const clearEmptyString = (data: IClearEmptyString): IClearEmptyString => {
    const list = Object.keys({ ...data });

    list.forEach((key) => {
        
        if (
            typeof data[key] === 'string'
            && !data[key].trim().length
        ) { 
            data[key] = null;
        }

        if (
            typeof data[key] === 'boolean'
        ) {
            data[key] = Number(data[key]);
        }
    });

    return data;
};