import * as yup from "yup";

const anyFieldFilled = (obj: any) => {
  if (obj === null) return false;
  return Object.values(obj).some((v) => v !== undefined && v !== null);
};

const makeConditionalRequired = (
  fieldSchema: any,
  fieldTitle: string,
  user = "author"
) => {
  return fieldSchema.test(
    "any-field-filled",
    `${user !== "author" ? "Opponent " : ""}${fieldTitle} is a required field`,
    function (this: yup.TestContext, value: any) {
      const allValues = this.parent;
      if (anyFieldFilled(allValues)) {
        return value !== undefined && value !== null;
      }
      return true;
    }
  );
};

const performanceStatsSchema = (user = "author") => {
  return yup
    .object()
    .shape({
      fg: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number in the range of 0-100")
          .min(0, "Should be a decimal number in the range of 0-100")
          .max(100, "Should be a decimal number in the range of 0-100"),
        "FG%",
        user
      ),
      threePT: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number in the range of 0-100")
          .min(0, "Should be a decimal number in the range of 0-100")
          .max(100, "Should be a decimal number in the range of 0-100"),
        "3PT%",
        user
      ),
      ft: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number in the range of 0-100")
          .min(0, "Should be a decimal number in the range of 0-100")
          .max(100, "Should be a decimal number in the range of 0-100"),
        "FT%",
        user
      ),
      releaseTime: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number")
          .min(0, "Should be a decimal number"),
        "Release Time",
        user
      ),
      releaseAngle: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number in the range of 0-180")
          .min(0, "Should be a decimal number in the range of 0-180")
          .max(180, "Should be a decimal number in the range of 0-180"),
        "Release Angle",
        user
      ),
      vertical: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number in the range of 0-180")
          .min(0, "Should be a decimal number in the range of 0-180")
          .max(180, "Should be a decimal number in the range of 0-180"),
        "Vertical",
        user
      ),
      speed: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number")
          .min(0, "Should be a decimal number"),
        "Speed",
        user
      ),
      legAngle: makeConditionalRequired(
        yup
          .number()
          .typeError("Should be a decimal number in the range of 0-180")
          .min(0, "Should be a decimal number in the range of 0-180")
          .max(180, "Should be a decimal number in the range of 0-180"),
        "Leg Angle",
        user
      ),
    })
    .nullable();
};

export const validationSchema = yup.object().shape({
  author: yup.number().required().min(1),
  opponent: yup.number().nullable(true).min(1),
  court: yup.number().required().min(1),
  score: yup.number().nullable(true).min(0).max(5),
  opponentScore: yup.number().nullable(true).min(0).max(5),
  win: yup.number().nullable(true).min(1),
  authorPerformanceStats: performanceStatsSchema(),
  opponentPerformanceStats: performanceStatsSchema("opponent"),
});
