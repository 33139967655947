import {
  IClearEmptyString,
  IGamePerformanceStats,
} from "../../../interfaces/model.interfaces";
import { IState } from "../EditForm";

const isArrayFullOfBlankStrings = (arr: any[]) => {
  return arr.every((item) => item === "");
};

export const createStatsCondition = (
  data: IClearEmptyString,
  authorPerformanceStats: IGamePerformanceStats,
  opponentPerformanceStats: IGamePerformanceStats
) => {
  return (
    data.status === "finished" &&
    ((!authorPerformanceStats &&
      data.authorPerformanceStats &&
      data.authorPerformanceStats.shotAreaChart instanceof File &&
      data.authorPerformanceStats.careerShotAreaChart instanceof File) ||
      (!opponentPerformanceStats &&
        data.opponentPerformanceStats &&
        data.opponentPerformanceStats.shotAreaChart instanceof File &&
        data.opponentPerformanceStats.careerShotAreaChart instanceof File))
  );
};

export const validateSecondUserCharts = (
  data: IClearEmptyString,
  authorPerformanceStats: IGamePerformanceStats,
  opponentPerformanceStats: IGamePerformanceStats
) => {
  return (
    data.status === "finished" &&
    ((!authorPerformanceStats &&
      data.authorPerformanceStats &&
      !opponentPerformanceStats &&
      data.opponentPerformanceStats &&
      (!(
        data.authorPerformanceStats.shotAreaChart instanceof File &&
        data.authorPerformanceStats.careerShotAreaChart instanceof File
      ) ||
        !(
          data.opponentPerformanceStats.shotAreaChart instanceof File &&
          data.opponentPerformanceStats.careerShotAreaChart instanceof File
        ))) ||
      (authorPerformanceStats &&
        data.authorPerformanceStats &&
        !opponentPerformanceStats &&
        data.opponentPerformanceStats &&
        ((data.authorPerformanceStats.shotAreaChart instanceof File &&
          data.authorPerformanceStats.careerShotAreaChart instanceof File) ||
          !(
            data.opponentPerformanceStats.shotAreaChart instanceof File &&
            data.opponentPerformanceStats.careerShotAreaChart instanceof File
          ))) ||
      (!authorPerformanceStats &&
        data.authorPerformanceStats &&
        opponentPerformanceStats &&
        data.opponentPerformanceStats &&
        (!(
          data.authorPerformanceStats.shotAreaChart instanceof File &&
          data.authorPerformanceStats.careerShotAreaChart instanceof File
        ) ||
          (data.opponentPerformanceStats.shotAreaChart instanceof File &&
            data.opponentPerformanceStats.careerShotAreaChart instanceof
              File))))
  );
};

export const validateChartsStatsCondition = (
  data: IClearEmptyString,
  authorPerformanceStats: IGamePerformanceStats,
  opponentPerformanceStats: IGamePerformanceStats
) => {
  return (
    data.status === "finished" &&
    ((!authorPerformanceStats &&
      data.authorPerformanceStats &&
      !isArrayFullOfBlankStrings(Object.values(data.authorPerformanceStats))) ||
      (!opponentPerformanceStats &&
        data.opponentPerformanceStats &&
        !isArrayFullOfBlankStrings(
          Object.values(data.opponentPerformanceStats)
        )))
  );
};

const isPartiallyFilled = (obj: IGamePerformanceStats) => {
  if (isArrayFullOfBlankStrings(Object.values(obj))) {
    return false;
  }

  const expectedKeys = [
    "fg",
    "threePT",
    "ft",
    "releaseTime",
    "releaseAngle",
    "vertical",
    "speed",
    "legAngle",
  ];

  const filledCount = Object.keys(obj).length;

  return filledCount > 0 && filledCount < expectedKeys.length;
};

export const validateStatsOnFullFilled = (values: IState) => {
  return (
    (values.authorPerformanceStats &&
      isPartiallyFilled(values.authorPerformanceStats)) ||
    (values.opponentPerformanceStats &&
      isPartiallyFilled(values.opponentPerformanceStats)) ||
    (values.authorPerformanceStats &&
      !values.opponentPerformanceStats &&
      !isArrayFullOfBlankStrings(
        Object.values(values.authorPerformanceStats)
      )) ||
    (!values.authorPerformanceStats &&
      values.opponentPerformanceStats &&
      !isArrayFullOfBlankStrings(
        Object.values(values.opponentPerformanceStats)
      ))
  );
};
