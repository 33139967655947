import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    ListProps,
} from 'react-admin';

const CountriesFilter = (props: unknown): JSX.Element => (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Name" source="name" />
    </Filter>
);

export const CountriesList = (props: ListProps): JSX.Element => (
    <List
        {...props}
        title="List of countries"
        filters={<CountriesFilter />}
        bulkActionButtons={false}
    >
        <Datagrid optimized>
            <TextField
                source="id"
            />
            <TextField
                source="name"
            />
        </Datagrid>
    </List>
);