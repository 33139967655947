import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Create,
    TextInput,
    EditButton,
    Filter,
    Edit,
    ListProps,
} from 'react-admin';
import { CreateForm } from './CreateForm';
import { EditForm } from './EditForm';

const CourtFilter = (props: unknown): JSX.Element =>  (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Name" source="name" />
        <TextInput label="Address" source="address" />
    </Filter>
);

export const CourtsList = (props: ListProps): JSX.Element =>(
    <List
        {...props}
        title="List of courts"
        filters={<CourtFilter />}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                source="name"
            />
            <TextField
                source="address"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const CourtCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <CreateForm {...props} />
    </Create>
);

export const CourtEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <EditForm {...props} /> 
    </Edit>
);