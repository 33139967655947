import React from 'react';
import { Admin, Resource, RouteWithoutLayout } from 'react-admin';
import { authProvider } from './providers/auth.provider';
import { dataProvider } from './providers/data.provider';
import LoginPage from './components/Auth/LoginPage';
import Forgot from './components/Auth/Forgot';
import LanguageIcon from '@material-ui/icons/Language';
import LandscapeIcon from '@material-ui/icons/Landscape';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import RoomIcon from '@material-ui/icons/Room';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { UsersList, UserCreate, UserEdit } from './components/Users';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CountriesList } from './components/Countries';
import { NationalitiesList } from './components/Nationalities';
import { ConfigurationsList, ConfigurationsEdit } from './components/Configurations';
import { CourtsList, CourtCreate, CourtEdit } from './components/Courts';
import { MembershipsList } from './components/Memberships';
import { GamesList, GameEdit, GameCreate } from './components/Games';
import { VideosList } from './components/Videos';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { VideoShow } from './components/Videos/Show';
import { TrainingCategoriesList, TrainingCategoryCreate, TrainingCategoryEdit } from './components/TrainingCategory';
import { TrainingsList, TrainingCreate, TrainingEdit } from './components/Trainings';
import { LevelList } from './components/Level';
import StarsIcon from '@material-ui/icons/Stars';
import { ProductCategoriesList } from './components/ProductCategories';
import { ProductSizesList } from './components/ProductSizes';
import HeightIcon from '@material-ui/icons/Height';
import ClassIcon from '@material-ui/icons/Class';
import CategoryIcon from '@material-ui/icons/Category';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { ProductsList, ProductCreate, ProductEdit } from './components/Products';
import { OrdersList, OrderShow } from './components/Orders';
import { MissionsList, MissionCreate, MissionEdit } from './components/Missions';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import DoneIcon from '@material-ui/icons/Done';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import { BannerList, BannerCreate, BannerEdit } from './components/Banners';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { MarketingVideosList, MarketingVideoCreate, MarketingVideoEdit } from './components/MarketingVideos';
import { DisputReasonsList, DisputReasonCreate, DisputReasonEdit } from './components/DisputReasons';

// eslint-disable-next-line react/prefer-stateless-function
const App = (): JSX.Element => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Admin
        title="Kotc admin"
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        customRoutes={[
          <RouteWithoutLayout
            key="forgot"
            exact
            path="/forgot"
            component={Forgot}
            noLayout
          />,
        ]}
      >
        <Resource
          name="users"
          list={UsersList}
          create={UserCreate}
          icon={PeopleIcon}
          edit={UserEdit}
        />
        <Resource
          name="countries"
          list={CountriesList}
          icon={LanguageIcon}
        />
        <Resource
          name="nationalities"
          list={NationalitiesList}
          icon={LandscapeIcon}
        />
        <Resource
          name="levels"
          list={LevelList}
          icon={StarsIcon}
        />
        <Resource
          name="courts"
          list={CourtsList}
          icon={RoomIcon}
          create={CourtCreate}
          edit={CourtEdit}
        />
        <Resource
          name="memberships"
          list={MembershipsList}
          icon={LoyaltyIcon}
        />
        <Resource
          name="configurations"
          list={ConfigurationsList}
          icon={SettingsIcon}
          edit={ConfigurationsEdit}
        />
        <Resource
          name="games"
          list={GamesList}
          icon={SportsBasketballIcon}
          edit={GameEdit}
          create={GameCreate}
        />
        <Resource
          name="videos"
          options={{
            label: 'Users Uploaded Videos',
          }}
          list={VideosList}
          icon={OndemandVideoIcon}
          show={VideoShow}
        />
        <Resource
          name="training-categories"
          list={TrainingCategoriesList}
          create={TrainingCategoryCreate}
          edit={TrainingCategoryEdit}
          icon={CategoryIcon}
        />
        <Resource
          name="trainings"
          list={TrainingsList}
          icon={FitnessCenterIcon}
          create={TrainingCreate}
          edit={TrainingEdit}
        />
        <Resource
          name="product-categories"
          list={ProductCategoriesList}
          icon={ClassIcon}
        />
        <Resource
          name="product-sizes"
          list={ProductSizesList}
          icon={HeightIcon}
        />
        <Resource
          name="products"
          list={ProductsList}
          icon={LocalOfferIcon}
          create={ProductCreate}
          edit={ProductEdit}
        />
        <Resource
          name="orders"
          list={OrdersList}
          icon={ShoppingBasketIcon}
          show={OrderShow}
        />
        <Resource
          name="missions"
          list={MissionsList}
          icon={DoneIcon}
          create={MissionCreate}
          edit={MissionEdit}
        />
        <Resource
          name="banners"
          list={BannerList}
          icon={ViewCarouselIcon}
          create={BannerCreate}
          edit={BannerEdit}
        />
        <Resource
          name="marketing"
          options={{
            label: 'KOTC Homepage Videos',
          }}
          list={MarketingVideosList}
          icon={VideoLibraryIcon}
          create={MarketingVideoCreate}
          edit={MarketingVideoEdit}
        />
        <Resource
          name="disput-reasons"
          options={{
            label: 'Disput reasons',
          }}
          list={DisputReasonsList}
          create={DisputReasonCreate}
          edit={DisputReasonEdit}
          icon={SmsFailedIcon}
        />
      </Admin>
    </MuiPickersUtilsProvider>
  );
};

export default App;
