import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Create,
    TextInput,
    Filter,
    ListProps,
    Edit,
    EditButton,
    FunctionField
} from 'react-admin';
import { CreateForm } from './CreateForm';
import { EditForm } from './EditForm';

const MarketingVideosFilter = (props: unknown): JSX.Element =>  (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Name" source="name" />
        <TextInput label="Link" source="link" />
    </Filter>
);

export const MarketingVideosList = (props: ListProps): JSX.Element =>(
    <List
        {...props}
        title="List of marketing videos"
        filters={<MarketingVideosFilter />}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                source="name"
            />
            <FunctionField
                render={(value: any) => value.type === 'liveStream' ? 'live stream' : value.type}
            />
            <TextField
                source="link"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const MarketingVideoCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <CreateForm {...props} />
    </Create>
);

export const MarketingVideoEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <EditForm {...props} />
    </Edit>
);