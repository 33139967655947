import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    Create,
    TextInput,
    EditButton,
    DateField,
    Filter,
    Edit,
    ListProps,
} from 'react-admin';
import { EditForm } from './EditForm';
import { CreateForm } from './CreateForm';

const UserFilter = (props: any): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Id" source="id" />
            <TextInput label="Username" source="username" />
            <TextInput label="Email" source="email" />
            <TextInput label="Role" source="role" />
        </Filter>
    );
};

export const UsersList = (props: ListProps): JSX.Element => {

    return (
        <List
            {...props}
            title="List of users"
            filters={<UserFilter />}
            sort={{
                field: 'id',
                order: 'DESC',
            }}
        >
            <Datagrid rowClick="edit">
                <TextField
                    source="id"
                />
                <TextField
                    source="username"
                />
                <EmailField
                    source="email"
                />
                <TextField
                    source="role"
                />
                <TextField
                    source="height"
                    emptyText="-"
                />
                <TextField
                    source="weight"
                    emptyText="-"
                />
                <DateField
                    source="dob"
                    emptyText="-"
                />
                <TextField
                    source="gender"
                    emptyText="-"
                />
                <TextField
                    label="Country"
                    source="country.name"
                    emptyText="-"
                />
                <TextField
                    label="Nationality"
                    source="nationality.name"
                    emptyText="-"
                />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export const UserCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <CreateForm {...props} />
    </Create>
);

export const UserEdit = (props: any): JSX.Element => {

    return (
        <Edit {...props}>
            <EditForm {...props} />
        </Edit>
    );
};