import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useLogin, useNotify } from 'react-admin';
import { Login } from 'react-admin';
import { TextField, Link, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ILogin } from './../../interfaces/auth.interfaces';
import { Link as RouteLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        display: 'none',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 3),
    },
    link: {
        margin: theme.spacing(2, 0, 1),
    },
    header: {
        margin: theme.spacing(3, 0, 0),
    },
}));

const LoginPage: React.FC = () => {

    const login = useLogin();

    const notify = useNotify();

    const [initialValues] = useState<ILogin>({
        email: '',
        password: '',
    });

    const validationSchema = yup.object().shape({
        email: yup.string()
            .trim()
            .required()
            .email()
            .max(45),
        password: yup.string()
            .trim()
            .required()
            .max(45),
    });

    const handleSubmit = useCallback(({ email, password }: ILogin) => {
        login({ email, password }).catch(() =>
            notify('Invalid email or password', 'error'),
        );
    }, [login, notify]);

    const classes = useStyles();

    const formComponent = (
        <React.Fragment>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<ILogin>) => (
                        <Container component="main" maxWidth="xs">
                            <h2 className={classes.header}>Sign in</h2>
                            <form
                                className={classes.form}
                                noValidate
                                onSubmit={props.handleSubmit}
                            >
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email"
                                    name="email"
                                    onChange={props.handleChange}
                                    value={props.values.email}
                                    error={props.touched.email && Boolean(props.errors.email)}
                                    helperText={props.touched.email && props.errors.email}
                                    autoFocus
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    onChange={props.handleChange}
                                    value={props.values.password}
                                    error={props.touched.password && Boolean(props.errors.password)}
                                    helperText={props.touched.password && props.errors.password}
                                />
                                <div
                                    className={classes.link}
                                >
                                    <Link
                                        component={RouteLink}
                                        to={'/forgot'}
                                    >
                                        Forgot password
                                    </Link>
                                </div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Sign In
                                </Button>
                            </form>
                        </Container>
                    )
                }
            </Formik>
        </React.Fragment>
    );


    return (
        <Login
            backgroundImage="https://source.unsplash.com/random/1600x900/?basketball"
            // eslint-disable-next-line react/no-children-prop
            children={formComponent}
            classes={classes}
        />
    );
};

export default LoginPage;