import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Create,
    TextInput,
    EditButton,
    Filter,
    Edit,
    ListProps,
} from 'react-admin';
import { CreateForm } from './CreateForm';
import { EditForm } from './EditForm';

const TrainCategoryFilter = (props: unknown): JSX.Element => (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Name" source="name" />
        <TextInput label="Level" source="level" />
    </Filter>
);

export const TrainingCategoriesList = (props: ListProps): JSX.Element => (
    <List
        {...props}
        title="List of training categories"
        filters={<TrainCategoryFilter />}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                source="name"
            />
            <TextField
                source="level"
            />
            <TextField
                source="order"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const TrainingCategoryCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <CreateForm {...props} />
    </Create>
);

export const TrainingCategoryEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <EditForm {...props} />
    </Edit>
);