import React, { useState, useCallback, useRef, ChangeEvent, useEffect } from 'react';
import { SimpleFormProps, useNotify, useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import * as yup from 'yup';
import { apiRequestProvider, extractErrorMessage } from './../../providers/api-request.provider';
import { ITrainingCategory, IMutationError, ITraining } from './../../interfaces/model.interfaces';
import { clearEmptyString } from './../../helpers/data-format.helper';
import { serialize } from 'object-to-formdata';
import { appConfig } from '../../config/app.config';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ITrainingState {
    name: string;
    category: string;
    file: File | string;
    points: number;
    order: number;
}

interface IEditTrainingProps {
    record: ITraining;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    fileBtn: {
        display: 'none',
    },
    fileName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    uploadBtn: {
        marginTop: theme.spacing(2),
    },
    videoContainer: {
        marginTop: theme.spacing(2),
    },
    video: {
        width: '100%',
    },
    btnTextMr: {
        marginRight: '1rem',
    },
    whiteColor: {
        color: 'white',
    },
}));

export const EditForm: React.FC<SimpleFormProps & IEditTrainingProps> = (
    props: SimpleFormProps,
) => {

    const { record: training, history } = props;

    const [initialValues, setInitValues] = useState<ITrainingState>({
        name: '',
        category: '',
        file: '',
        points: 250,
        order: 0,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required()
            .max(45),
        category: yup.string()
            .trim()
            .required(),
        description: yup.string()
            .trim()
            .max(255),
        points: yup.number()
            .nullable(true)
            .min(0),
        order: yup.number()
            .nullable(true)
            .min(0),
    });

    useEffect(() => {

        setInitValues({
            ...training,
            category: training.category?.id || null,
        });

    }, [training]);

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const { data: categories } = useQuery({
        type: 'getList',
        resource: 'training-categories',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    const notify = useNotify();

    const classes = useStyles();

    const onSuccess = useCallback(() => {
        setLoading(false);
        notify('Training updated successful', 'success');
        history.push('/trainings');
    }, [notify, history, setLoading]);

    const onFailure = useCallback((error: IMutationError) => {
        setLoading(false);
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify, setLoading]);

    const handleSubmit = useCallback(async (values: ITrainingState) => {

        const data = clearEmptyString(values);

        if (!data.file) {
            notify('Video is required', 'error');
        }

        const formData = serialize(data);

        setLoading(true);

        await apiRequestProvider({
            url: `/trainings/${training.id}`,
            method: 'PATCH',
            data: formData,
            onSuccess,
            onFailure,
        });

    }, [onSuccess, onFailure, notify, training]);

    const maxFileSize = 52428800;

    const handleChangeVideo = useCallback((event: ChangeEvent<any>, props: FormikProps<ITrainingState>) => {

        const [file] = event.target.files;

        const uploadFile = file as File;

        const [type] = uploadFile.type.split('/');

        if (type !== 'video') {

            notify('Uploaded file must be video', 'error');

            return false;
        }

        if (uploadFile.size > maxFileSize) {
            notify(`File could not be more than 50MB (${maxFileSize})`, 'error');

            return false;
        }

        props.setFieldValue('file', file);
    }, [maxFileSize, notify]);

    const onChangeVideo = useCallback(() => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    }, []);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<ITrainingState>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Edit training</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        className={classes.formControl}
                                                        error={Boolean(props.touched.category && props.errors.category)}
                                                    >
                                                        {!props.values.category && <InputLabel>Category</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Category"
                                                            labelId="category-select-label"
                                                            id="category-select"
                                                            name="category"
                                                            value={String(props.values.category)}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            {categories && categories.map((category: ITrainingCategory, i: number) => (
                                                                <MenuItem key={category.id || i} value={category.id || i}>{category.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {props.touched?.category && props.errors?.category &&
                                                            <FormHelperText>
                                                                {props.errors.category}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Points"
                                                        name="points"
                                                        onChange={props.handleChange}
                                                        value={props.values.points}
                                                        error={props.touched.points && Boolean(props.errors.points)}
                                                        helperText={props.touched.points && props.errors.points}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Order"
                                                        name="order"
                                                        onChange={props.handleChange}
                                                        value={props.values.order}
                                                        error={props.touched.order && Boolean(props.errors.order)}
                                                        helperText={props.touched.order && props.errors.order}
                                                    />
                                                </FormGroup>

                                                <div>
                                                    <div>
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            className={classes.fileBtn}
                                                            ref={fileInput}
                                                            accept="video/*"
                                                            onChange={(e) => handleChangeVideo(e, props)}
                                                        />
                                                    </div>
                                                    {props.values.file && typeof props.values.file === 'string' &&
                                                        <div className={classes.videoContainer}>
                                                            <video className={classes.video} controls>
                                                                <source src={`${appConfig.apiEndpoint}${props.values.file}`} />
                                                            </video>
                                                        </div>
                                                    }
                                                    <div>
                                                        <Button
                                                            size="large"
                                                            onClick={onChangeVideo}
                                                            color="primary"
                                                            variant="outlined"
                                                            className={classes.uploadBtn}
                                                        >
                                                            Upload video
                                                        </Button>
                                                        <div>
                                                            {props.values.file &&
                                                                <p
                                                                    className={classes.fileName}
                                                                >
                                                                    {props.values.file instanceof File ? props.values.file.name : ''}
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>


                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    <span className={loading ? classes.btnTextMr : ''}>Submit</span>
                                                    {loading ? <CircularProgress className={classes.whiteColor} size={25} /> : null}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};
