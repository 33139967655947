import React, { useState, useEffect, useCallback } from 'react';
import { SimpleFormProps, useNotify, useDataProvider } from 'react-admin';
import { IConfigurations } from '../../interfaces/model.interfaces';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Container,
    Grid,
    FormGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IMutationError } from '../../interfaces/model.interfaces';

export interface IEditConfigurationsProps {
    record: IConfigurations;
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 3),
    },
    link: {
        margin: theme.spacing(2, 0, 1),
    },
    header: {
        margin: theme.spacing(3, 0, 0),
    },
    container: {
        padding: theme.spacing(0, 2, 0),
    },
    fieldContainer: {
        width: '100%',
    },
    formControl: {
        minWidth: 120,
        width: '100%',
        marginTop: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    calendarField: {
        width: '100%',
    },
    avatar: {
        display: 'none',
    },
}));

export const EditForm: React.FC<SimpleFormProps & IEditConfigurationsProps> = (
    props: SimpleFormProps,
) => {

    const { record: config, history } = props;

    const [initialValues, setInitialValues] = useState<IConfigurations>({
        mvpCost: 1.99,
        nearestCourt: 3000,
        discount: 5,
    });

    const validationSchema = yup.object().shape({
        mvpCost: yup.number()
            .required('MVP Cost is a required field')
            .min(0, 'MVP Cost must be greater than or equal to 0'),
        nearestCourt: yup.number()
            .required('Nearest Court is a required field')
            .min(0, 'Nearest Court must be greater than or equal to 0'),
        discount: yup.number()
            .required('Discount is a required field')
            .min(0, 'Discount must be greater than or equal to 0'),
    });

    useEffect(() => {

        const data = {
            mvpCost: config.mvpCost,
            nearestCourt: config.nearestCourt,
            discount: config.discount,
        };

        setInitialValues(data);
    }, [config]);

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const onSuccess = useCallback(() => {
        notify('Configuration update successful', 'success');
        history.push('/configurations');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        if (Array.isArray(error.message)) {
            const messages = error.message.map(m => m.message);
            notify(messages[0], 'error');
        } else {
            notify('Something went wrong', 'error');
        }
    }, [notify]);

    const handleSubmit = useCallback((data: IConfigurations) => {

        dataProvider.update('configurations', {
            id: config.id,
            data: data,
            previousData: config,
        }, {
            onSuccess,
            onFailure,
        });

    }, [dataProvider, config, onSuccess, onFailure]);

    const classes = useStyles();

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IConfigurations>) => (
                        <Container
                            component="main"
                            maxWidth={false}
                            className={classes.container}
                        >
                            <Grid container>
                                <h2 className={classes.header}>Edit MVP</h2>
                                <form
                                    className={classes.form}
                                    noValidate
                                    onSubmit={props.handleSubmit}
                                >
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                margin="normal"
                                                label="Mvp cost"
                                                name="mvpCost"
                                                onChange={props.handleChange}
                                                value={props.values.mvpCost}
                                                error={props.touched.mvpCost && Boolean(props.errors.mvpCost)}
                                                helperText={props.touched.mvpCost && props.errors.mvpCost}
                                                autoFocus
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                margin="normal"
                                                label="Nearest court distance"
                                                name="nearestCourt"
                                                onChange={props.handleChange}
                                                value={props.values.nearestCourt}
                                                error={props.touched.nearestCourt && Boolean(props.errors.nearestCourt)}
                                                helperText={props.touched.nearestCourt && props.errors.nearestCourt}
                                                autoFocus
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                margin="normal"
                                                label="Mvp discount"
                                                name="discount"
                                                onChange={props.handleChange}
                                                value={props.values.discount}
                                                error={props.touched.discount && Boolean(props.errors.discount)}
                                                helperText={props.touched.discount && props.errors.discount}
                                                autoFocus
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Button
                                            type="submit"
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>
                        </Container>
                    )
                }
            </Formik>
        </div>
    );
};