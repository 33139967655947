import { IMissionState } from './EditForm';

export const excludeDataByType = (data: IMissionState) => {

    const { type } = data;

    if (type === 'training') {

        delete data['wins'];
    }

    if (type === 'game') {
        delete data['category'];
    }

    return data;
}