import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  calendarField: {
    width: "100%",
  },
  avatarImg: {
    maxWidth: 300,
    marginBottom: theme.spacing(1),
  },
  avatarBtn: {
    display: "none",
  },
  avatarName: {
    marginTop: theme.spacing(1),
  },
  trigger: {
    margin: theme.spacing(2, 0),
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 2, 3),
  },
  header: {
    marginButton: 0,
  },
  video: {
    width: "100%",
  },
  delete: {
    color: "rgb(220, 0, 78)",
  },
  hidden: {
    display: "none",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  chartImg: {
    height: 300,
    maxWidth: "33vw",
    marginBottom: theme.spacing(1),
  },
  delimiter: {
    borderBottom: "1px solid black",
    paddingTop: "10px",
    width: "100%",
    margin: "0 12px",
  },
  uploadChartBtn: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  uploadText: {
    maxWidth: "33vw",
    wordWrap: "break-word",
  },
  userSelect: {
    width: "49.28%",
  },
}));
