import { IMutationError } from "../interfaces/model.interfaces";
import { appConfig } from "./../config/app.config";
import axios from "axios";

interface IOptions {
  url: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  headers?: Record<string, unknown>;
  data: Record<string, unknown> | FormData;
  onSuccess: (response: unknown) => void;
  onFailure: (error: IMutationError) => void;
}

export const apiRequestProvider = async ({
  url,
  method,
  data,
  headers = {},
  onSuccess,
  onFailure,
}: IOptions): Promise<void> => {
  const { apiEndpoint } = appConfig;

  if (!(data instanceof FormData)) {
    headers["Accept"] = "application/json";
  }

  const auth = localStorage.getItem("auth");

  if (auth) {
    const { token } = JSON.parse(auth);

    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await axios({
      url: `${apiEndpoint}${url}`,
      headers: headers,
      data: data,
      method: method,
    });

    onSuccess(response.data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errors: IMutationError = error.response.data;

    onFailure(errors);
  }
};

export const extractErrorMessage = (error: IMutationError): string => {
  if (Array.isArray(error.message)) {
    const messages = error.message.map((m) => m.message);
    return messages[0];
  } else if (typeof error.message === "string") {
    return error.message;
  } else {
    return "Something went wrong";
  }
};
