import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ShowProps,
    useDataProvider,
    useQuery
} from 'react-admin';
import { appConfig } from './../../config/app.config';
import { IVideo, IVideoResponse } from './../../interfaces/model.interfaces';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 0,
    },
    video: {
        margin: theme.spacing(2, 0),
        width: '75%',
    },
}));

export const VideoShow: React.FC<ShowProps> = (props: ShowProps): JSX.Element => {

    const classes = useStyles();

    const [video, setVideo] = useState<IVideo | null>(null);

    const { id } = props;

    const dataProvider = useDataProvider();

    if (id && !video) {
        dataProvider.getOne('videos', {
            id: id,
        }, {
            onSuccess: (res: IVideoResponse) => {
                setVideo(res.data)
            },
            onFailure: (err: any) => {
                console.log(err)
            }
        });
    }

    return (
        <Show {...props}>
            <SimpleShowLayout>
                {video &&
                    <div>
                        <h2 className={classes.header}>View video</h2>
                        <div>
                            <video className={classes.video} controls>
                                <source src={`${appConfig.apiEndpoint}${video.file}`}/>
                            </video>
                        </div>
                        <p>{video.name}</p>
                    </div>
                }
            </SimpleShowLayout>
        </Show>
    );
}