import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Create,
    TextInput,
    EditButton,
    Filter,
    Edit,
    ListProps,
} from 'react-admin';
import { DisputReasonsForm } from './DisputReasonsForm';

const DisputReasonsFilter = (props: unknown): JSX.Element => (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Name" source="name" />
    </Filter>
);

export const DisputReasonsList = (props: ListProps): JSX.Element => (
    <List
        {...props}
        title="Disput reasons"
        filters={<DisputReasonsFilter />}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                source="name"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const DisputReasonCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <DisputReasonsForm {...props} />
    </Create>
);

export const DisputReasonEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <DisputReasonsForm {...props} />
    </Edit>
);