import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ListProps,
    Edit,
    TextInput,
    Filter,
    Create,
    FunctionField,
} from 'react-admin';
import { EditForm } from './EditForm';
import { CreateForm } from './CreateForm';
import moment from 'moment';

const GameFilter = (props: any): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Id" source="id" />
            <TextInput label="Status" source="status" />
            <TextInput label="Court" source="court.id" />
            <TextInput label="Author" source="author.id" />
            <TextInput label="Opponent" source="opponent.id" />
        </Filter>
    );
};

export const GamesList = (props: ListProps): JSX.Element => {

    return (
        <List
            {...props}
            title="List of games"
            filters={<GameFilter />}
            sort={{
                field: 'id',
                order: 'DESC',
            }}
            
        >
            <Datagrid rowClick="edit">
                <TextField
                    source="id"
                />
                <TextField
                    source="status"
                />
                <FunctionField
                    label="Date"
                    render={(record: any) => moment(record.date).format('YYYY-MM-DD h:mm:ss')}
                />
                <TextField
                    label="Author id"
                    source="author.id"
                    emptyText="-"
                />
                <TextField
                    label="Opponent id"
                    source="opponent.id"
                    emptyText="-"
                />
                <TextField
                    label="Disput"
                    source="disput.status"
                    emptyText="-"
                />
                <TextField
                    label="Court id"
                    source="court.id"
                    emptyText="-"
                />

                <EditButton />
            </Datagrid>
        </List>
    );
};

export const GameEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <EditForm {...props} />
    </Edit>
);

export const GameCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <CreateForm {...props} />
    </Create>
)