import axios from "axios";
import { appConfig } from "../../../config/app.config";

export const uploadStats = async (formData: FormData, token: string) => {
  await axios.post(
    `${appConfig.apiEndpoint}/game-performance-stats/upload-stats`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
