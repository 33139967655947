import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    ListProps,
    ShowButton,
    Show,
    SelectInput
} from 'react-admin';
import { ShowForm } from './Show';

const OrdersFilter = (props: unknown): JSX.Element => (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <SelectInput
            source="status"
            allowEmpty={false}
            choices={[
                { id: 'pending', name: 'Pending' },
                { id: 'finished', name: 'Finished' },
                { id: 'canceled', name: 'Canceled' },
                { id: 'failed', name: 'Failed' },
            ]}
        />
        <TextInput label="User" source="user.id" />
    </Filter>
);

export const OrdersList = (props: ListProps): JSX.Element => (
    <List
        {...props}
        title="List of orders"
        filters={<OrdersFilter />}
        bulkActionButtons={false}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid optimized>
            <TextField
                source="id"
            />
            <TextField
                source="status"
            />
            <TextField
                label="Currency cost"
                source="totalCost"
            />
            <TextField
                label="Ring cost"
                source="totalRings"
            />
            <TextField
                label="User id"
                source="user.id"
            />
            <ShowButton />
        </Datagrid>
    </List>
);

export const OrderShow = (props: any): JSX.Element => (
    <Show {...props}>
        <ShowForm {...props} />
    </Show>
);