import React, { useCallback } from 'react';
import { SimpleFormProps, useNotify } from 'react-admin';
import { extractErrorMessage, apiRequestProvider } from './../../providers/api-request.provider';
import {
    IProductForm,
    IMutationError,
} from './../../interfaces/model.interfaces';
import { ProductForm } from './ProductFrom';
import { clearEmptyString } from '../../helpers/data-format.helper';
import { serialize } from 'object-to-formdata';


export const CreateForm: React.FC<SimpleFormProps> = (
    props: SimpleFormProps,
) => {

    const { history } = props;

    const notify = useNotify();

    const onSuccess = useCallback(() => {
        notify('Product created successful', 'success');
        history.push('/products');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async(values: IProductForm) => {
        
        const data = clearEmptyString(values);

        if (!data.picture) {
            notify('Picture is required', 'error');
        }

        if (data.picture) {
            const formData = serialize(data);
            
            await apiRequestProvider({
                url: '/products',
                method: 'POST',
                data: formData,
                onSuccess,
                onFailure,
            });
        }

    }, [notify, onSuccess, onFailure]);

    return (
        <div>
            <ProductForm 
                header="Create product"
                handleSubmit={handleSubmit}
            />
        </div>
    );
};