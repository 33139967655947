import React, { useRef, useCallback, useState } from "react";
import { appConfig } from "./../../config/app.config";
import {
  ChunkedUploady,
  useBatchStartListener,
  useBatchFinishListener,
} from "@rpldy/chunked-uploady";
import UploadButton from "@rpldy/upload-button";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Select, MenuItem } from "@material-ui/core";
import { useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { extractErrorMessage } from "./../../providers/api-request.provider";
import { IMutationError, IVideo } from "../../interfaces/model.interfaces";
import { IGame } from "./../../interfaces/model.interfaces";
import { useStyles } from "./helpers/styles";

interface IBtnProps {
  game: IGame;
  addVideo: (video: IVideo) => void;
  setUploading: (uploading: boolean) => void;
}

interface IUploadProgressProps {
  setUploading: (uploading: boolean) => void;
  isButtonEnabled: boolean;
  alreadyUploaded: boolean;
}

const useStyle = makeStyles((theme) => ({
  chunkBtn: {
    display: "none",
  },
  btnTextMr: {
    marginRight: "1rem",
  },
  container: {
    marginTop: theme.spacing(1),
  },
}));

const UploadProgress = ({
  setUploading,
  isButtonEnabled,
  alreadyUploaded,
}: IUploadProgressProps) => {
  const [load, setLoad] = useState<boolean>(false);

  const classes = useStyle();

  const notify = useNotify();

  const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onChangeUpload = useCallback(() => {
    if (!fileInput.current) {
      return;
    }

    fileInput.current.click();
  }, []);

  useBatchStartListener(() => {
    setLoad(true);
    if (setUploading) {
      setUploading(true);
    }
  });

  useBatchFinishListener(() => {
    setLoad(false);

    notify("File was uploaded successful", "success");

    if (setUploading) {
      setUploading(false);
    }
  });

  return (
    <div className={classes.container}>
      <UploadButton ref={fileInput} className={classes.chunkBtn} />
      <Button
        size="large"
        onClick={onChangeUpload}
        color="primary"
        variant="outlined"
        disabled={alreadyUploaded || !isButtonEnabled || load}
      >
        <span className={load ? classes.btnTextMr : ""}>Upload video</span>
        {load ? <CircularProgress size={25} /> : null}
      </Button>
    </div>
  );
};

export const ChunkUploadBtn = ({
  game,
  addVideo,
  setUploading,
}: IBtnProps): JSX.Element => {
  const notify = useNotify();

  const classes = useStyles();

  const [videoUser, setVideoUser] = useState("author");

  const [alreadyUploaded, setAlreadyUploaded] = useState(false);

  const auth = localStorage.getItem("auth");

  const token = auth ? JSON.parse(auth).token : "";

  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
  };

  const maxFileSize = 52428800;

  const onValidateSize = useCallback(
    (file: unknown) => {
      const uploadFile = file as File;

      const [type] = uploadFile.type.split("/");

      if (type !== "video") {
        notify("Uploaded file must be video", "error");

        return false;
      }

      // if (uploadFile.size > maxFileSize) {
      //     notify(`File could not be more than 50MB (${maxFileSize})`, 'error');

      //     return false;
      // }

      return true;
    },
    [maxFileSize, notify]
  );

  const handleResponse = useCallback(
    (res: string, status: number) => {
      if (status >= 400) {
        const data = JSON.parse(res) as IMutationError;

        const error = extractErrorMessage(data);

        notify(error, "error");
      }

      if (res && status >= 200 && status <= 300) {
        const data = JSON.parse(res) as IVideo;

        addVideo(data);

        setAlreadyUploaded(true);
      }
    },
    [addVideo, notify]
  );

  const handleChangeUser = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (typeof e.target.value === "string") setVideoUser(e.target.value);
  };

  const isUploadEnable =
    videoUser === "author"
      ? !game.videos?.find((video) => video?.user?.id === game?.author?.id)
      : !game.videos?.find((video) => video?.user?.id === game?.opponent?.id);

  const userId = videoUser === "author" ? game?.author?.id : game?.opponent?.id;

  return (
    <>
      <Select
        className={classes.userSelect}
        value={videoUser}
        onChange={handleChangeUser}
      >
        <MenuItem value={"author"}>Author</MenuItem>
        <MenuItem value={"opponent"}>Opponent</MenuItem>
      </Select>
      <ChunkedUploady
        destination={{
          url: `${appConfig.apiEndpoint}/videos?userId=${userId}`,
          headers,
          params: {
            game: game.id,
          },
        }}
        accept="video/*"
        fileFilter={onValidateSize}
        // debug={true}
        chunkSize={appConfig.chunkSize}
        forceJsonResponse
        formatServerResponse={handleResponse}
      >
        <UploadProgress
          setUploading={setUploading}
          isButtonEnabled={isUploadEnable}
          alreadyUploaded={alreadyUploaded}
        />
      </ChunkedUploady>
    </>
  );
};
