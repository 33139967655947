import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  SimpleFormProps,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  IGame,
  IGamePerformanceStats,
  IMutationError,
  IVideo,
} from "./../../interfaces/model.interfaces";
import { ChunkUploadBtn } from "./ChunkUploadBtn";
import moment from "moment";
import { Formik, FormikProps } from "formik";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  Button,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { DateType } from "@date-io/type";
import { extractErrorMessage } from "./../../providers/api-request.provider";
import { appConfig } from "../../config/app.config";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { clearEmptyString } from "./../../helpers/data-format.helper";
import GamePerformanceStatsForm from "./GamePerformanceStatsForm";
import { uploadStats } from "./helpers/upload-stats";
import { useStyles } from "./helpers/styles";
import { validationSchema } from "./helpers/validation";
import {
  createStatsCondition,
  validateChartsStatsCondition,
  validateSecondUserCharts,
} from "./helpers/conditions";

interface IEditGameProps {
  record: IGame;
}

export interface IState {
  author: number | null;
  opponent: number | null;
  court: number | null;
  date: Date | null;
  score: number | null;
  opponentScore: number | null;
  status: string;
  win: number | null;
  fg?: number | null;
  disputStatus?: string | null;
  authorPerformanceStats?: IGamePerformanceStats | null;
  opponentPerformanceStats?: IGamePerformanceStats | null;
}

export const EditForm: React.FC<SimpleFormProps & IEditGameProps> = (
  props: SimpleFormProps
) => {
  const { record: game, history } = props;
  const authorPerformanceStats =
    game.gamePerformanceStats?.find(
      (el: IGamePerformanceStats) => el.user?.id === game.author?.id
    ) || null;
  const opponentPerformanceStats =
    game.gamePerformanceStats?.find(
      (el: IGamePerformanceStats) => el.user?.id === game.opponent?.id
    ) || null;

  const [initialValues, setInitialValues] = useState<IState>({
    date: null,
    opponent: null,
    author: null,
    court: null,
    score: null,
    opponentScore: null,
    status: "new",
    win: null,
    disputStatus: null,
    authorPerformanceStats: null,
    opponentPerformanceStats: null,
  });

  const [videos, setVideos] = useState<IVideo[] | null>(null);

  const [uploading, setUploading] = useState<boolean>(false);

  const dataProvider = useDataProvider();

  const notify = useNotify();

  const submitBtn = useRef() as React.MutableRefObject<HTMLInputElement>;

  const refresh = useRefresh();

  useEffect(() => {
    setInitialValues({
      date: game.date,
      opponent: game.opponent?.id || null,
      author: game.author.id,
      court: game.court?.id,
      score: game.score,
      opponentScore: game.opponentScore,
      status: game.status,
      win: game.win?.id || null,
      disputStatus: game.disput ? game.disput.status : null,
      authorPerformanceStats,
      opponentPerformanceStats,
    });

    if (!videos) {
      setVideos(game.videos);
    }
  }, [game, videos, authorPerformanceStats, opponentPerformanceStats]);

  useEffect(() => {
    refresh();
  }, [videos]);

  const onSuccess = useCallback(() => {
    notify("Game update successful", "success");
    history.push("/games");
  }, [notify, history]);

  const onFailure = useCallback(
    (error: IMutationError) => {
      const message = extractErrorMessage(error);
      notify(message, "error");
    },
    [notify]
  );

  const postStats = async (
    values: IState,
    data: Partial<IState>,
    token: string
  ) => {
    const formData = new FormData();
    if (values?.authorPerformanceStats?.shotAreaChart) {
      formData.append(
        "shotChart",
        values?.authorPerformanceStats?.shotAreaChart
      );
    }
    if (values?.authorPerformanceStats?.careerShotAreaChart) {
      formData.append(
        "careerShotChart",
        values?.authorPerformanceStats?.careerShotAreaChart
      );
    }
    if (values?.opponentPerformanceStats?.shotAreaChart) {
      formData.append(
        "opponentShotChart",
        values?.opponentPerformanceStats?.shotAreaChart
      );
    }
    if (values?.opponentPerformanceStats?.careerShotAreaChart) {
      formData.append(
        "opponentCareerShotChart",
        values?.opponentPerformanceStats?.careerShotAreaChart
      );
    }

    const statsArray = [
      { ...values.authorPerformanceStats, user: { id: data.author } },
      { ...values.opponentPerformanceStats, user: { id: data.opponent } },
    ];
    formData.append("stats", JSON.stringify(statsArray));

    await uploadStats(formData, token);
  };

  const handleSubmit = useCallback(
    async (values: IState) => {
      const data = clearEmptyString(values);
      const auth = localStorage.getItem("auth");
      const token = auth ? JSON.parse(auth).token : null;

      let shouldPostStats = false;

      if (
        createStatsCondition(
          data,
          authorPerformanceStats,
          opponentPerformanceStats
        )
      ) {
        if (
          validateSecondUserCharts(
            data,
            authorPerformanceStats,
            opponentPerformanceStats
          )
        ) {
          notify("Second user charts required", "error");
          return;
        }
        shouldPostStats = true;
      } else if (
        validateChartsStatsCondition(
          data,
          authorPerformanceStats,
          opponentPerformanceStats
        )
      ) {
        notify("Charts required!", "error");
        return;
      } else if (authorPerformanceStats || opponentPerformanceStats) {
        shouldPostStats = true;
      }

      if (shouldPostStats) {
        try {
          await postStats(values, data, token);
        } catch (error: any) {
          notify(error.response.data.message, "error");
          return;
        }
      }

      await dataProvider.update(
        "games",
        { id: game.id, data, previousData: game },
        { onSuccess, onFailure }
      );
    },
    [
      game,
      authorPerformanceStats,
      opponentPerformanceStats,
      dataProvider,
      onSuccess,
      onFailure,
    ]
  );

  const classes = useStyles();

  const handleChangeDate = useCallback(
    (val: DateType | null, props: FormikProps<IState>) => {
      const date = val ? val : new Date();

      props.setFieldValue("date", date.toISOString());
    },
    []
  );

  const onAddVideo = useCallback(
    (video: IVideo) => {
      const list = videos ? [...videos, video] : [video];

      setVideos(list);
    },
    [videos]
  );

  const onDeleteVideo = useCallback(
    (video: IVideo) => {
      dataProvider.delete(
        "videos",
        {
          id: video.id,
          previousData: video,
        },
        {
          onSuccess: () => {
            notify("Video was deleted successful", "success");

            if (videos) {
              const index = videos.findIndex((v) => v.id === video.id);

              if (index !== -1) {
                videos.splice(index, 1);

                setVideos([...videos]);
              }
            }
          },
          onFailure,
        }
      );
    },
    [dataProvider, notify, onFailure, videos, setVideos]
  );

  const onSubmitBtn = useCallback(() => {
    submitBtn.current.click();
  }, [submitBtn]);

  const onChangeDisputStatus = (props: FormikProps<IState>, value: unknown) => {
    if (value) {
      props.setFieldValue("disputStatus", value);

      if (game.status === "canceled" && value === "resolved") {
        props.setFieldValue("status", "pending");
      } else if (value === "pending") {
        props.setFieldValue("status", game.status);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const videoLable = (video: IVideo) =>
    video?.user?.id === game.author.id
      ? " - Author's video"
      : video?.user?.id === game.opponent.id
      ? " - Opponent's video"
      : "";

  const customSort = (videos: IVideo[], game: IGame) => {
    const order = {
      [`${game?.author?.id ?? "defaultAuthor"}`]: 1,
      [`${game?.opponent?.id ?? "defaultOpponent"}`]: 2,
    };

    return videos.sort((videoA, videoB) => {
      const idA = videoA?.user?.id ?? "defaultA";
      const idB = videoB?.user?.id ?? "defaultB";

      return (order[idA] ?? 3) - (order[idB] ?? 3);
    });
  };

  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    setTrigger(!trigger);
    if (!trigger) {
      setTrigger(true);
    }
  }, [game]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props: FormikProps<IState>) => (
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <h2 className={classes.header}>Edit game</h2>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <form noValidate onSubmit={props.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        margin="normal"
                        label="Author"
                        name="author"
                        onChange={props.handleChange}
                        value={props.values.author}
                        error={
                          props.touched.author && Boolean(props.errors.author)
                        }
                        helperText={props.touched.author && props.errors.author}
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        margin="normal"
                        label="score"
                        name="score"
                        onChange={props.handleChange}
                        value={props.values.score}
                        error={
                          props.touched.score && Boolean(props.errors.score)
                        }
                        helperText={props.touched.score && props.errors.score}
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        margin="normal"
                        label="Court"
                        name="court"
                        onChange={props.handleChange}
                        value={props.values.court}
                        error={
                          props.touched.court && Boolean(props.errors.court)
                        }
                        helperText={props.touched.court && props.errors.court}
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControl className={classes.formControl}>
                        {!props.values.status && (
                          <InputLabel>Status</InputLabel>
                        )}
                        <Select
                          fullWidth
                          label="Status"
                          labelId="status-select-label"
                          id="status-select"
                          name="status"
                          value={props.values.status}
                          onChange={props.handleChange}
                          className={classes.selectEmpty}
                          displayEmpty
                        >
                          <MenuItem value="new">New</MenuItem>
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="canceled">Canceled</MenuItem>
                          <MenuItem value="finished">Finished</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        margin="normal"
                        label="Opponent"
                        name="opponent"
                        onChange={props.handleChange}
                        value={props.values.opponent}
                        error={
                          props.touched.opponent &&
                          Boolean(props.errors.opponent)
                        }
                        helperText={
                          props.touched.opponent && props.errors.opponent
                        }
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        margin="normal"
                        label="Opponent score"
                        name="opponentScore"
                        onChange={props.handleChange}
                        value={props.values.opponentScore}
                        error={
                          props.touched.opponentScore &&
                          Boolean(props.errors.opponentScore)
                        }
                        helperText={
                          props.touched.opponentScore &&
                          props.errors.opponentScore
                        }
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                    <FormGroup>
                      <DateTimePicker
                        fullWidth
                        autoOk
                        ampm={false}
                        variant="inline"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date"
                        name="date"
                        maxDate={moment().add(10, "day").toString()}
                        value={
                          props.values.date
                            ? new Date(props.values.date).toISOString()
                            : null
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={(date: DateType | null) =>
                          handleChangeDate(date, props)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        fullWidth
                        type="number"
                        margin="normal"
                        label="Winner"
                        name="win"
                        onChange={props.handleChange}
                        value={props.values.win || ""}
                        error={props.touched.win && Boolean(props.errors.win)}
                        helperText={props.touched.win && props.errors.win}
                        onKeyPress={handleKeyPress}
                      />
                    </FormGroup>
                  </Grid>
                  {props.values.status === "finished" &&
                    Boolean(videos?.length) && (
                      <>
                        {trigger &&
                          (authorPerformanceStats ||
                            opponentPerformanceStats) && (
                            <GamePerformanceStatsForm
                              props={props}
                              classes={classes}
                              game={game}
                            />
                          )}
                        {trigger &&
                          !authorPerformanceStats &&
                          !opponentPerformanceStats && (
                            <GamePerformanceStatsForm
                              props={props}
                              classes={classes}
                              game={game}
                            />
                          )}
                        {!trigger &&
                          (authorPerformanceStats ||
                            opponentPerformanceStats) && (
                            <GamePerformanceStatsForm
                              props={props}
                              classes={classes}
                              game={game}
                            />
                          )}
                        {!trigger &&
                          !authorPerformanceStats &&
                          !opponentPerformanceStats && (
                            <GamePerformanceStatsForm
                              props={props}
                              classes={classes}
                              game={game}
                            />
                          )}
                      </>
                    )}

                  {videos && (
                    <Grid item md={12} sm={12}>
                      <Grid container spacing={3}>
                        {customSort(videos, game).map((video: IVideo) => (
                          <Grid key={video.id} item md={6} xs={12}>
                            <div>
                              <video className={classes.video} controls>
                                <source
                                  src={`${appConfig.apiEndpoint}${video.file}`} /* type='video/mov' */
                                />
                              </video>
                              <IconButton
                                aria-label="delete"
                                className={classes.delete}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => onDeleteVideo(video)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <span>
                                {video.name}
                                {videoLable(video)}
                              </span>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item md={12} sm={12}>
                    <input
                      className={classes.hidden}
                      type="submit"
                      ref={submitBtn}
                    />
                  </Grid>
                </Grid>
              </form>
              <Grid item md={12} sm={12}>
                <FormGroup>
                  <ChunkUploadBtn
                    game={game}
                    addVideo={onAddVideo}
                    setUploading={setUploading}
                  />
                </FormGroup>
              </Grid>
              {game.disput && (
                <>
                  <Grid item md={12} sm={12}>
                    <FormGroup>
                      <p>
                        <b>Disput</b>
                      </p>
                      <p>
                        <b>Reason</b>:{" "}
                        {game.disput?.otherReason
                          ? game.disput?.otherReason
                          : game.disput?.reason?.name}
                      </p>
                      <p>
                        <b>Remark</b>: {game.disput.comment}
                      </p>
                    </FormGroup>
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <Select
                      disabled={game.disput.status === "resolved"}
                      fullWidth
                      label="Disput status"
                      labelId="disput-status-label"
                      id="disput-status"
                      name="disputStatus"
                      value={props.values.disputStatus}
                      // onChange={props.handleChange}
                      onChange={(e) =>
                        onChangeDisputStatus(props, e.target.value)
                      }
                      className={classes.selectEmpty}
                      displayEmpty
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="resolved">Resolved</MenuItem>
                    </Select>
                  </Grid>
                </>
              )}
              <Grid item md={12} sm={12}>
                <div>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={onSubmitBtn}
                    className={classes.submit}
                    disabled={uploading}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Formik>
    </div>
  );
};
