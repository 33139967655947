import React, { useState, useCallback, useRef, ChangeEvent, useEffect } from 'react';
import { SimpleFormProps, useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ICourt, IMutationError } from './../../interfaces/model.interfaces';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    FormGroup,
} from '@material-ui/core';
import { appConfig } from './../../config/app.config';
import { clearEmptyString } from './../../helpers/data-format.helper';
import { serialize } from 'object-to-formdata';
import { apiRequestProvider, extractErrorMessage } from './../../providers/api-request.provider';

interface IEditCourtProps {
    record: ICourt;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
    avatarBtn: {
        display: 'none',
    },
    avatarName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
}));

export const EditForm: React.FC<SimpleFormProps & IEditCourtProps> = (
    props: SimpleFormProps,
) => {

    const { history, record: court } = props;

    const [initialValues, setInitialValues] = useState<ICourt>({
        name: '',
        lat: 0,
        lng: 0,
        address: '',
        picture: '',
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required()
            .max(45),
        address: yup.string()
            .trim()
            .required()
            .max(255),
        lat: yup.number(),
        lng: yup.number(),
    });

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const classes = useStyles();

    useEffect(() => {

        const data = {
            ...court,
            lat: court.location.lat,
            lng: court.location.lng,
        };

        setInitialValues(data);

    }, [court]);

    const onSuccess = useCallback(() => {
        notify('Court update successful', 'success');
        history.push('/courts');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);

        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async (values: ICourt) => {

        const dataValues = {
            ...values,
            location: {
                lat: values.lat,
                lng: values.lng,
            },
        };

        const data = clearEmptyString(dataValues);

        if (data.picture && data.picture instanceof File) {
            const formData = serialize(data);

            await apiRequestProvider({
                url: `/courts/${court.id}`,
                method: 'PATCH',
                data: formData,
                onSuccess,
                onFailure,
            });

        } else {
            dataProvider.update('courts', {
                id: court.id,
                data,
                previousData: court,
            }, {
                onSuccess,
                onFailure,
            });
        }

    }, [dataProvider, court, onSuccess, onFailure]);

    const handleChangePicture = useCallback(async (event: ChangeEvent<any>, props: FormikProps<ICourt>) => {
        event.stopPropagation();
        event.preventDefault();

        const file: File = event.target.files[0];

        props.setFieldValue('picture', file);
    }, []);

    const onClickPicture = useCallback(() => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    }, [fileInput]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<ICourt>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Edit court</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Address"
                                                        name="address"
                                                        onChange={props.handleChange}
                                                        value={props.values.address}
                                                        error={props.touched.address && Boolean(props.errors.address)}
                                                        helperText={props.touched.address && props.errors.address}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Latitude"
                                                        name="lat"
                                                        inputProps={{
                                                            step: 'any',
                                                        }}
                                                        onChange={props.handleChange}
                                                        value={props.values.lat}
                                                        error={props.touched.lat && Boolean(props.errors.lat)}
                                                        helperText={props.touched.lat && props.errors.lat}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        type="number"
                                                        label="Longitude"
                                                        name="lng"
                                                        inputProps={{
                                                            step: 'any',
                                                        }}
                                                        onChange={props.handleChange}
                                                        value={props.values.lng}
                                                        error={props.touched.lng && Boolean(props.errors.lng)}
                                                        helperText={props.touched.lng && props.errors.lng}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                {props.values.picture && typeof props.values.picture === 'string' &&
                                                    <div>
                                                        <img
                                                            className={classes.avatarImg}
                                                            src={`${appConfig.apiEndpoint}/${props.values.picture}`}
                                                        />
                                                    </div>
                                                }

                                                <input
                                                    className={classes.avatarBtn}
                                                    type="file"
                                                    name="avatar"
                                                    ref={fileInput}
                                                    accept="image/jpeg, image/png"
                                                    // eslint-disable-next-line react/jsx-no-bind
                                                    onChange={(e) => handleChangePicture(e, props)}
                                                />
                                                <Button
                                                    size="large"
                                                    onClick={onClickPicture}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    Upload avatar
                                                </Button>
                                                <>
                                                    {props.values.picture &&
                                                        <p
                                                            className={classes.avatarName}
                                                        >
                                                            {props.values.picture instanceof File ? props.values.picture.name : ''}
                                                        </p>
                                                    }
                                                </>
                                            </Grid>
                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};