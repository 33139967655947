import React, { useState, useCallback } from 'react';
import { SimpleFormProps, useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { IMarketingVideo, IMutationError } from './../../interfaces/model.interfaces';
import { Formik, FormikProps } from 'formik';
import { extractErrorMessage } from './../../providers/api-request.provider';
import {
    TextField,
    Grid,
    FormGroup,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
    avatarBtn: {
        display: 'none',
    },
    avatarName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const CreateForm: React.FC<SimpleFormProps> = (
    props: SimpleFormProps,
) => {

    const { history } = props;

    const [initialValues] = useState<IMarketingVideo>({
        name: '',
        type: '',
        link: '',
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required('Name is required fields')
            .max(45),
        type: yup.mixed()
            .required('Type is required field'),
        link: yup.string()
            .trim()
            .required('Link is required fields')
            .max(255),
    });

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const classes = useStyles();

    const onSuccess = useCallback(() => {
        notify('Court update successful', 'success');
        history.push('/marketing');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async(data: IMarketingVideo) => {

        dataProvider.create('marketing', {
            data,
        }, {
            onSuccess,
            onFailure,
        });

    }, [onSuccess, onFailure, dataProvider]);


    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IMarketingVideo>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Create marketing video</h2>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Link"
                                                        name="link"
                                                        onChange={props.handleChange}
                                                        value={props.values.link}
                                                        error={props.touched.link && Boolean(props.errors.link)}
                                                        helperText={props.touched.link && props.errors.link}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <FormControl
                                                        className={classes.formControl}
                                                        error={Boolean(props.errors.type)}
                                                    >
                                                        <InputLabel
                                                            id="type-select-label"
                                                        >
                                                            Type
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Type"
                                                            labelId="type-select-label"
                                                            id="type-select"
                                                            name="type"
                                                            value={props.values.type}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            <MenuItem
                                                                value="highlight"
                                                            >
                                                                Highlight
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="nba"
                                                            >
                                                                Nba
                                                            </MenuItem>
                                                            <MenuItem
                                                                value="liveStream"
                                                            >
                                                                Live stream
                                                            </MenuItem>
                                                        </Select>
                                                        {props.errors?.type &&
                                                            <FormHelperText>
                                                                {props.errors.type}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};