import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    ListProps,
} from 'react-admin';

const NationalitiesFilter = (props: any): JSX.Element => {

    return (
        <Filter {...props}>
            <TextInput label="Id" source="id" />
            <TextInput label="Name" source="name" />
        </Filter>
    );
};

export const NationalitiesList = (props: ListProps): JSX.Element => {

    return (
        <List
            {...props}
            title="List of nationalities"
            filters={<NationalitiesFilter />}
            bulkActionButtons={false}
        >
            <Datagrid optimized>
                <TextField
                    source="id"
                />
                <TextField
                    source="name"
                />
            </Datagrid>
        </List>
    );
};