import React, { useState, useCallback, useRef, ChangeEvent, useEffect } from 'react';
import { SimpleFormProps, useNotify, useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import * as yup from 'yup';
import { apiRequestProvider, extractErrorMessage } from './../../providers/api-request.provider';
import { ITrainingCategory, IMutationError, IMission } from './../../interfaces/model.interfaces';
import { clearEmptyString } from './../../helpers/data-format.helper';
import { serialize } from 'object-to-formdata';
import { appConfig } from '../../config/app.config';
import { excludeDataByType } from './form-data.helper';

export interface IMissionState {
    name: string;
    description: string;
    category?: string | null;
    picture: File | string;
    points: number;
    wins?: number | null;
    type: 'training' | 'game';
}

interface IEditTrainingProps {
    record: IMission;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    fileBtn: {
        display: 'none',
    },
    fileName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    uploadBtn: {
        marginTop: theme.spacing(2),
    },
    videoContainer: {
        marginTop: theme.spacing(2),
    },
    video: {
        width: '100%',
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
}));

export const EditForm: React.FC<SimpleFormProps & IEditTrainingProps> = (
    props: SimpleFormProps,
) => {

    const { record: mission, history } = props;

    const [initialValues, setInitValues] = useState<IMissionState>({
        name: '',
        description: '',
        category: '',
        picture: '',
        points: 250,
        wins: 0,
        type: 'training',
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required()
            .max(45),
        description: yup.string()
            .trim()
            .required()
            .max(2000),
        points: yup.number()
            .nullable(true)
            .min(1),
        wins: yup.number()
            .nullable(true),
    });

    useEffect(() => {

        const initValues = {
            ...mission,
            category: mission.category?.id || null,
        };

        setInitValues({
            ...initValues,
        });

    }, [mission]);

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const { data: categories } = useQuery({
        type: 'getList',
        resource: 'training-categories',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    const notify = useNotify();

    const classes = useStyles();

    const onSuccess = useCallback(() => {
        notify('Mission updated successful', 'success');
        history.push('/missions');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async (values: IMissionState) => {

        const { type, category, wins } = values;

        excludeDataByType(values);

        if (type === 'training' && !category) {
            return notify('Category is required to training mission', 'error');
        }

        if (type === 'game' && !wins) {
            return notify('Wins is required to game mission', 'error');
        }

        if (!values.picture) {
            return notify('Picture is required', 'error');
        }

        const data = clearEmptyString(values);

        const formData = serialize(data);

        await apiRequestProvider({
            url: `/missions/${mission.id}`,
            method: 'PATCH',
            data: formData,
            onSuccess,
            onFailure,
        });

    }, [onSuccess, onFailure, notify, mission]);

    const handleChangePicture = useCallback((event: ChangeEvent<any>, props: FormikProps<IMissionState>) => {

        const [file] = event.target.files;

        const uploadFile = file as File;

        const [type] = uploadFile.type.split('/');

        if (type !== 'image') {

            notify('Uploaded file must be picture', 'error');

            return false;
        }

        props.setFieldValue('picture', file);
    }, [notify]);

    const onChangePicture = useCallback(() => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    }, []);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IMissionState>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Edit mission</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Description"
                                                        name="description"
                                                        rows={3}
                                                        multiline
                                                        onChange={props.handleChange}
                                                        value={props.values.description}
                                                        error={props.touched.description && Boolean(props.errors.description)}
                                                        helperText={props.touched.description && props.errors.description}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        className={classes.formControl}
                                                        error={Boolean(props.touched.category && props.errors.category)}
                                                    >
                                                        {!props.values.category && <InputLabel>Type</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Type"
                                                            labelId="type-select-label"
                                                            id="type-select"
                                                            name="type"
                                                            value={String(props.values.type)}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="training">Training</MenuItem>
                                                            <MenuItem value="game">Game</MenuItem>
                                                        </Select>
                                                        {props.touched?.type && props.errors?.type &&
                                                            <FormHelperText>
                                                                {props.errors.type}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Points"
                                                        name="points"
                                                        onChange={props.handleChange}
                                                        value={props.values.points}
                                                        error={props.touched.points && Boolean(props.errors.points)}
                                                        helperText={props.touched.points && props.errors.points}
                                                    />
                                                </FormGroup>
                                                {props.values.type === 'training' &&
                                                    <FormGroup>
                                                        <FormControl
                                                            className={classes.formControl}
                                                            error={Boolean(props.touched.category && props.errors.category)}
                                                        >
                                                            {!props.values.category && <InputLabel>Category</InputLabel>}
                                                            <Select
                                                                fullWidth
                                                                label="Category"
                                                                labelId="category-select-label"
                                                                id="category-select"
                                                                name="category"
                                                                value={String(props.values.category)}
                                                                onChange={props.handleChange}
                                                                className={classes.selectEmpty}
                                                                displayEmpty
                                                            >
                                                                {categories && categories.map((category: ITrainingCategory, i: number) => (
                                                                    <MenuItem key={category.id || i} value={category.id || i}>{category.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {props.touched?.category && props.errors?.category &&
                                                                <FormHelperText>
                                                                    {props.errors.category}
                                                                </FormHelperText>
                                                            }
                                                        </FormControl>
                                                    </FormGroup>
                                                }
                                                {props.values.type === 'game' &&
                                                    <FormGroup>
                                                        <TextField
                                                            fullWidth
                                                            type="number"
                                                            margin="normal"
                                                            label="Wins"
                                                            name="wins"
                                                            onChange={props.handleChange}
                                                            value={props.values.wins}
                                                            error={props.touched.wins && Boolean(props.errors.wins)}
                                                            helperText={props.touched.wins && props.errors.wins}
                                                        />
                                                    </FormGroup>
                                                }
                                                <div>
                                                    <div>
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            className={classes.fileBtn}
                                                            ref={fileInput}
                                                            accept="image/*"
                                                            onChange={(e) => handleChangePicture(e, props)}
                                                        />
                                                    </div>
                                                    {props.values.picture && typeof props.values.picture === 'string' &&
                                                        <div className={classes.videoContainer}>
                                                            <img
                                                                className={classes.avatarImg}
                                                                src={`${appConfig.apiEndpoint}/${props.values.picture}`}
                                                            />
                                                        </div>
                                                    }
                                                    <div>
                                                        <Button
                                                            size="large"
                                                            onClick={onChangePicture}
                                                            color="primary"
                                                            variant="outlined"
                                                            className={classes.uploadBtn}
                                                        >
                                                            Upload picture
                                                        </Button>
                                                        <div>
                                                            {props.values.picture &&
                                                                <p
                                                                    className={classes.fileName}
                                                                >
                                                                    {props.values.picture instanceof File ? props.values.picture.name : ''}
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>


                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};
