import React, { useState, useCallback, useEffect } from 'react';
import { IOrderShipment } from './../../interfaces/model.interfaces';
import {
    TextField,
    Grid,
    FormGroup,
    Button,
} from '@material-ui/core';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';

export interface IProps {
    shipment: IOrderShipment,
    onSubmit: (data: IOrderShipment) => void,
    disabled: boolean,
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 0, 3),
    },
    formField: {
        marginTop: theme.spacing(1),
    },
}));

export const ShipmentForm: React.FC<any> = (
    props: IProps,
) => {

    const { shipment, onSubmit, disabled = false, } = props;

    const classes = useStyles();

    const [initValues, setInitialValues] = useState<IOrderShipment>({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zipCode: 0,
        phone: '',
        default: 0,
    });

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .trim()
            .required('First name is required fields')
            .max(45),
        lastName: yup.string()
            .trim()
            .required('Last name is required fields')
            .max(45),
        address: yup.string()
            .trim()
            .required('Address is required fields')
            .max(45),
        city: yup.string()
            .trim()
            .required('City is required fields')
            .max(45),
        state: yup.string()
            .trim()
            .required('State is required fields')
            .max(45),
        zipCode: yup.number()
            .required('Zip cod is required fields')
            .min(0),
        phone: yup.string()
            .trim()
            .required('Phone cod is required fields')
            .max(45),
    });

    const handleSubmit = useCallback((data: any) => {
        onSubmit(data);
    }, [onSubmit]);

    useEffect(() => {
        setInitialValues(shipment);
    }, [shipment]);

    return (
        <div className={classes.root}>
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IOrderShipment>) => (
                        <div>
                            <form
                                onSubmit={props.handleSubmit}
                            >
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="First name"
                                                name="firstName"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.firstName}
                                                error={props.touched.firstName && Boolean(props.errors.firstName)}
                                                helperText={props.touched.firstName && props.errors.firstName}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Last name"
                                                name="lastName"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.lastName}
                                                error={props.touched.lastName && Boolean(props.errors.lastName)}
                                                helperText={props.touched.lastName && props.errors.lastName}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Address"
                                                name="address"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.address}
                                                error={props.touched.address && Boolean(props.errors.address)}
                                                helperText={props.touched.address && props.errors.address}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="City"
                                                name="city"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.city}
                                                error={props.touched.city && Boolean(props.errors.city)}
                                                helperText={props.touched.city && props.errors.city}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="State"
                                                name="state"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.state}
                                                error={props.touched.state && Boolean(props.errors.state)}
                                                helperText={props.touched.state && props.errors.state}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Zip code"
                                                type="number"
                                                name="zipCode"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.zipCode}
                                                error={props.touched.zipCode && Boolean(props.errors.zipCode)}
                                                helperText={props.touched.zipCode && props.errors.zipCode}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Phone"
                                                name="Phone"
                                                disabled={disabled}
                                                className={classes.formField}
                                                onChange={props.handleChange}
                                                value={props.values.phone}
                                                error={props.touched.phone && Boolean(props.errors.phone)}
                                                helperText={props.touched.phone && props.errors.phone}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Submit
                                </Button>
                            </form>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};