import React, { useState, useCallback } from 'react';
import { SimpleFormProps, useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import * as yup from 'yup';
import { extractErrorMessage } from './../../providers/api-request.provider';
import { ITrainingCategory, IMutationError } from './../../interfaces/model.interfaces';
import { clearEmptyString } from './../../helpers/data-format.helper';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
    avatarBtn: {
        display: 'none',
    },
    avatarName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const CreateForm: React.FC<SimpleFormProps> = (
    props: SimpleFormProps,
) => {

    const { history } = props;

    const [initialValues] = useState<ITrainingCategory>({
        name: '',
        level: '',
        description: '',
        order: 0,
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required()
            .max(45),
        level: yup.string()
            .trim()
            .required(),
        description: yup.string()
            .nullable(true)
            .trim()
            .max(255),
        order: yup.number()
            .nullable(true)
            .min(0),
    });

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const classes = useStyles();

    const onSuccess = useCallback(() => {
        notify('Category created successful', 'success');
        history.push('/training-categories');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async (values: ITrainingCategory) => {

        const data = clearEmptyString(values);

        dataProvider.create('training-categories', {
            data,
        }, {
            onSuccess,
            onFailure,
        });

    }, [onSuccess, onFailure, dataProvider]);


    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<ITrainingCategory>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Create category</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        className={classes.formControl}
                                                        error={Boolean(props.errors.level)}
                                                    >
                                                        {!props.values.level && <InputLabel>Level</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Level"
                                                            labelId="level-select-label"
                                                            id="level-select"
                                                            name="level"
                                                            value={props.values.level}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value=" ">N/A</MenuItem>
                                                            <MenuItem value="simple">Simple</MenuItem>
                                                            <MenuItem value="medium">Medium</MenuItem>
                                                            <MenuItem value="hard">Hard</MenuItem>
                                                        </Select>
                                                        {props.errors?.level &&
                                                            <FormHelperText>
                                                                {props.errors.level}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Description"
                                                        name="description"
                                                        rows={3}
                                                        multiline
                                                        onChange={props.handleChange}
                                                        value={props.values.description}
                                                        error={props.touched.description && Boolean(props.errors.description)}
                                                        helperText={props.touched.description && props.errors.description}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Order"
                                                        name="order"
                                                        onChange={props.handleChange}
                                                        value={props.values.order}
                                                        error={props.touched.order && Boolean(props.errors.order)}
                                                        helperText={props.touched.order && props.errors.order}
                                                    />
                                                </FormGroup>
                                            </Grid>

                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};
