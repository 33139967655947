import React, { useState, useCallback, useEffect, useRef } from 'react';
import { SimpleFormProps, useDataProvider, useNotify } from 'react-admin';
import { IMutationError } from './../../interfaces/model.interfaces';
import * as yup from 'yup';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
    TextField,
    Grid,
    FormGroup,
    Button,
} from '@material-ui/core';
import {
    DateTimePicker,
} from '@material-ui/pickers';
import { DateType } from '@date-io/type';
import { extractErrorMessage } from './../../providers/api-request.provider';
import { clearEmptyString } from './../../helpers/data-format.helper';

interface IState {
    author: number | null;
    opponent: number | null;
    court: number | null;
    date: Date | null;
    score: number | null;
    opponentScore: number | null;
    status: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    calendarField: {
        width: '100%',
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
    avatarBtn: {
        display: 'none',
    },
    avatarName: {
        marginTop: theme.spacing(1),
    },
    trigger: {
        margin: theme.spacing(2, 0),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    video: {
        width: '100%',
    },
    delete: {
        color: 'rgb(220, 0, 78)',
    },
    hidden: {
        display: 'none',
    },
    submit: {
        marginTop: theme.spacing(2),
    },
}));

export const CreateForm: React.FC<SimpleFormProps> = (
    props: SimpleFormProps,
) => {

    const { history } = props;

    const [initialValues, setInitialValues] = useState<IState>({
        date: null,
        opponent: null,
        author: null,
        court: null,
        score: null,
        opponentScore: null,
        status: 'new',
    });

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const submitBtn = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {

        setInitialValues({
            date: new Date(),
            opponent: null,
            author: null,
            court: null,
            score: null,
            opponentScore: null,
            status: 'new',
        });

    }, []);

    const validationSchema = yup.object().shape({
        author: yup.number()
            .required()
            .min(1),
        opponent: yup.number()
            .nullable(true)
            .min(1),
        court: yup.number()
            .required()
            .min(1),
        score: yup.number()
            .nullable(true)
            .min(0),
        opponentScore: yup.number()
            .nullable(true)
            .min(0),
    });

    const onSuccess = useCallback(() => {
        notify('Game update successful', 'success');
        history.push('/games');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback((values: IState) => {

        const data = clearEmptyString(values);

        dataProvider.create('games', {
            data: data,
        }, {
            onSuccess,
            onFailure,
        });

    }, [dataProvider, onSuccess, onFailure]);

    const classes = useStyles();

    const handleChangeDate = useCallback((val: DateType | null, props: FormikProps<IState>) => {

        const date = val ? val : new Date();

        props.setFieldValue('date', date.toISOString());
    }, []);


    const onSubmitBtn = useCallback(() => {
        submitBtn.current.click();
    }, [submitBtn]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IState>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Create game</h2>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <form
                                    noValidate
                                    onSubmit={props.handleSubmit}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <FormGroup>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin="normal"
                                                    label="Author"
                                                    name="author"
                                                    onChange={props.handleChange}
                                                    value={props.values.author}
                                                    error={props.touched.author && Boolean(props.errors.author)}
                                                    helperText={props.touched.author && props.errors.author}
                                                />
                                            </FormGroup>
                                            {/* <FormGroup>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin="normal"
                                                    label="score"
                                                    name="score"
                                                    onChange={props.handleChange}
                                                    value={props.values.score}
                                                    error={props.touched.score && Boolean(props.errors.score)}
                                                    helperText={props.touched.score && props.errors.score}
                                                />
                                            </FormGroup> */}
                                            <FormGroup>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin="normal"
                                                    label="Court"
                                                    name="court"
                                                    onChange={props.handleChange}
                                                    value={props.values.court}
                                                    error={props.touched.court && Boolean(props.errors.court)}
                                                    helperText={props.touched.court && props.errors.court}
                                                />
                                            </FormGroup>
                                            {/* <FormGroup>
                                                <FormControl className={classes.formControl}>
                                                    {!props.values.status && <InputLabel>Status</InputLabel>}
                                                    <Select
                                                        disabled
                                                        fullWidth
                                                        label="Status"
                                                        labelId="status-select-label"
                                                        id="status-select"
                                                        name="status"
                                                        value={props.values.status}
                                                        onChange={props.handleChange}
                                                        className={classes.selectEmpty}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="new">New</MenuItem>
                                                        <MenuItem value="pending">Pending</MenuItem>
                                                        <MenuItem value="canceled">Canceled</MenuItem>
                                                        <MenuItem value="finished">Finished</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </FormGroup> */}
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <FormGroup>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin="normal"
                                                    label="Opponent"
                                                    name="opponent"
                                                    onChange={props.handleChange}
                                                    value={props.values.opponent}
                                                    error={props.touched.opponent && Boolean(props.errors.opponent)}
                                                    helperText={props.touched.opponent && props.errors.opponent}
                                                />
                                            </FormGroup>
                                            {/* <FormGroup>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    margin="normal"
                                                    label="Opponent score"
                                                    name="opponentScore"
                                                    onChange={props.handleChange}
                                                    value={props.values.opponentScore}
                                                    error={props.touched.opponentScore && Boolean(props.errors.opponentScore)}
                                                    helperText={props.touched.opponentScore && props.errors.opponentScore}
                                                />
                                            </FormGroup> */}
                                            <FormGroup>
                                                <DateTimePicker
                                                    fullWidth
                                                    autoOk
                                                    ampm={false}
                                                    variant="inline"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="Date"
                                                    name="date"
                                                    maxDate={moment().add(10, 'day').toString()}
                                                    value={props.values.date ? new Date(props.values.date).toISOString() : null}
                                                    // eslint-disable-next-line react/jsx-no-bind
                                                    onChange={(date: DateType | null) => handleChangeDate(date, props)}
                                                />
                                            </FormGroup>
                                        </Grid>

                                        <Grid item md={12} sm={12}>
                                            <input
                                                className={classes.hidden}
                                                type="submit"
                                                ref={submitBtn}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid item md={12} sm={12}>
                                    <div>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            onClick={onSubmitBtn}
                                            className={classes.submit}

                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};