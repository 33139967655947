import React, { useState, useCallback, useEffect } from 'react';
import { SimpleFormProps, useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    FormGroup,
} from '@material-ui/core';
import * as yup from 'yup';
import { extractErrorMessage } from './../../providers/api-request.provider';
import { IDisputReason, IMutationError } from './../../interfaces/model.interfaces';
import { clearEmptyString } from './../../helpers/data-format.helper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
}));

export interface IDisputReasonFormProps {
    record?: IDisputReason,
}

export const DisputReasonsForm: React.FC<SimpleFormProps & IDisputReasonFormProps> = (
    props: SimpleFormProps
) => {

    const { history, record } = props;

    const [initialValues, setInitialValues] = useState<IDisputReason>({
        name: '',
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required()
            .max(255),
    });

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const classes = useStyles();

    useEffect(() => {
        if (record) {
            setInitialValues({ ...initialValues, name: record.name });
        }
    }, [record]);

    const onSuccess = useCallback(() => {
        notify(`Disput reason ${record ? 'edit' : 'created'} successful`, 'success');
        history.push('/disput-reasons');
    }, [notify, history, record]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async (values: IDisputReason) => {

        const data = clearEmptyString(values);

        if (data.name === record?.name) {
            return onSuccess();
        }

        if (record?.id) {

            dataProvider.update('disput-reasons', {
                id: record.id,
                data: {...data},
                previousData: record,
            }, {
                onSuccess,
                onFailure,
            });

        } else {

            dataProvider.create('disput-reasons', {
                data,
            }, {
                onSuccess,
                onFailure,
            });
        }

    }, [onSuccess, onFailure, dataProvider, record]);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IDisputReason>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>{record?.id ? 'Edit' : 'Create'} disput reason</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                            </Grid>

                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};