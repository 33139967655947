import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    ListProps,
    FunctionField,
} from 'react-admin';

const MembershipsFilter = (props: any): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="User id" source="user.id" />
        </Filter>
    );
};

export const MembershipsList = (props: ListProps): JSX.Element => {

    return (
        <List
            {...props}
            title="List of memberships"
            filters={<MembershipsFilter />}
            sort={{
                field: 'id',
                order: 'DESC',
            }}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="edit" optimized>
                <TextField
                    source="id"
                />
                <TextField
                    source="service"
                />
                <TextField
                    label="User id"
                    source="user.id"
                />
                <TextField
                    label="Status"
                    source="status"
                />
                <FunctionField
                    label="Created at"
                    render={(record: any) => `${new Date(record.createdAt).toDateString()}`}
                />
                <FunctionField
                    label="End period"
                    render={(record: any) => `${new Date(record.endPeriod * 1000).toDateString()}`}
                />
            </Datagrid>
        </List>
    );

};