import crudProvider from 'ra-data-nestjsx-crud';
import { appConfig } from './../config/app.config';
import { IHttpClientOptions } from './../interfaces/config.interface';
import { fetchUtils } from 'react-admin';

export const httpClient = (url: string, options: IHttpClientOptions = {}): Promise<{
    status: number;
    headers: Headers;
    body: string;
    json: unknown;
}> => {

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    
    const auth = localStorage.getItem('auth');

    if (auth) {
        const { token } = JSON.parse(auth);

        options.headers.set('Authorization', `Bearer ${token}`);
        // options.headers.set('Content-Type', 'multipart/form-data');
    }

    return fetchUtils.fetchJson(url, options);
};

export const dataProvider = crudProvider(appConfig.apiEndpoint, httpClient);