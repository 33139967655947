import React, { useCallback } from 'react';
import { SimpleFormProps, useNotify } from 'react-admin';
import { IMutationError, IOrder, IOrderProduct, IOrderShipment } from './../../interfaces/model.interfaces';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { appConfig } from '../../config/app.config';
import { apiRequestProvider, extractErrorMessage } from './../../providers/api-request.provider';
import { ShipmentForm } from './ShipmentForm';

interface IShowOrderProps {
    record: IOrder;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    list: {
        listStyle: 'none',
        paddingLeft: 0,
        '& b': {
            paddingRight: theme.spacing(0.5),
        },
    },
    finishBtn: {
        marginRight: theme.spacing(1),
    },
}));


export const ShowForm: React.FC<SimpleFormProps & IShowOrderProps> = (
    props: SimpleFormProps,
) => {

    const { record: order, history } = props;

    const classes = useStyles();

    const notify = useNotify();

    const onFinish = useCallback(async () => {

        await apiRequestProvider({
            url: `/orders/${order.id}/finish`,
            method: 'PATCH',
            data: {},
            onSuccess: () => {
                notify('Order was finished successful', 'success');
                history.push('/orders');
            },
            onFailure: (error: IMutationError) => {
                const message = extractErrorMessage(error);
                notify(message, 'error');
            },
        });
    }, [history, notify, order.id]);

    const onCancel = useCallback(async() => {
        await apiRequestProvider({
            url: `/orders/${order.id}/cancel`,
            method: 'PATCH',
            data: {},
            onSuccess: () => {
                notify('Order was canceled successful', 'success');
                history.push('/orders');
            },
            onFailure: (error: IMutationError) => {
                const message = extractErrorMessage(error);
                notify(message, 'error');
            },
        });
    }, [history, notify, order.id]);

    const onChangeShipment = useCallback(async(data: IOrderShipment) => {

        const values = {
            shipment: data,
        };

        await apiRequestProvider({
            url: `/orders/${order.id}`,
            method: 'PATCH',
            data: values,
            onSuccess: () => {
                notify('Shipment was updated successful', 'success');
            },
            onFailure: (error: IMutationError) => {
                const message = extractErrorMessage(error);
                notify(message, 'error');
            },
        });

    }, [notify, order.id]);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <h2>Show order</h2>
                </Grid>
                <Grid item sm={12}>
                    <Button
                        className={classes.finishBtn}
                        variant="contained"
                        color="primary"
                        onClick={onFinish}
                        disabled={order.status !== 'pending'}
                    >
                        Finish
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={onCancel}
                        disabled={order.status !== 'pending'}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={3}>
                        <Grid item md={6} sm={12}>
                            <h3>User info</h3>
                            <ul className={classes.list}>
                                <li>
                                    <b>Id:</b>
                                    {order.user.id}
                                </li>
                                <li>
                                    <b>Email:</b>
                                    {order.user.email}
                                </li>
                                <li>
                                    <b>Username:</b>
                                    {order.user.username}
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <h3>Order info</h3>
                            <ul className={classes.list}>
                                <li>
                                    <b>Total rings:</b>
                                    {order.totalRings}
                                </li>
                                <li>
                                    <b>Total cost:</b>
                                    {order.totalCost}
                                </li>
                                <li>
                                    <b>Status: </b>
                                    {order.status}
                                </li>
                                <li>
                                    <b>Discount: </b>
                                    {order.discount}
                                </li>
                                {order.stripeOrderId &&
                                    <li>
                                        <b>Stripe id: </b>
                                        {order.stripeOrderId}
                                    </li>
                                }
                            </ul>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <h3>Orders info</h3>
                            {order?.orderProducts?.map((item: IOrderProduct) => {
                                return (
                                    <div key={item.id}>
                                        <ul className={classes.list}>
                                            <li>
                                                <b>Id:</b>
                                                {item.product.id}
                                            </li>
                                            <li>
                                                <b>Name: </b>
                                                {item.product.name}
                                            </li>
                                            <li>
                                                <b>Price: </b>
                                                {item.product.price}
                                            </li>
                                            <li>
                                                <b>Ring price: </b>
                                                {item.product.ringPrice}
                                            </li>
                                            <li>
                                                <b>Rating: </b>
                                                {item.product.rating}
                                            </li>
                                            <li>
                                                <b>Size: </b>
                                                {item?.size?.name || '-'}
                                            </li>
                                        </ul>
                                        {item.product.picture && typeof item.product.picture === 'string' &&
                                            <img
                                                src={`${appConfig.apiEndpoint}/${item.product.picture}`}
                                                width="150"
                                            />
                                        }
                                    </div>
                                );
                            })}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <h3>Shipment info</h3>
                            <ShipmentForm
                                shipment={order.shipment}
                                onSubmit={onChangeShipment}
                                disabled={order.status !== 'pending'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};