import React, { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react';
import { SimpleFormProps, useQuery, useNotify, useDataProvider } from 'react-admin';
import { IUser, ICountry, INationality } from './../../interfaces/model.interfaces';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Switch,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IMutationError } from './../../interfaces/model.interfaces';
import {
    DatePicker,
} from '@material-ui/pickers';
import { DateType } from '@date-io/type';
import { clearEmptyString } from './../../helpers/data-format.helper';
import { serialize } from 'object-to-formdata';
import { appConfig } from './../../config/app.config';
import { apiRequestProvider, extractErrorMessage } from './../../providers/api-request.provider';

export interface IEditUserProps {
    record: IUser;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    calendarField: {
        width: '100%',
    },
    avatarImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
    },
    avatarBtn: {
        display: 'none',
    },
    avatarName: {
        marginTop: theme.spacing(1),
    },
    trigger: {
        margin: theme.spacing(2, 0),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
}));

export const EditForm: React.FC<SimpleFormProps & IEditUserProps> = (
    props: SimpleFormProps,
) => {

    const { record: user, history } = props;

    const [initialValues, setInitialValues] = useState<IUser>({
        username: '',
        email: '',
        weight: null,
        height: null,
        dob: '',
        gender: ' ',
        role: 'user',
        country: null,
        nationality: null,
        password: '',
        avatar: '',
        mvp: 0,
        rings: 0,
    });

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const { data: countries } = useQuery({
        type: 'getList',
        resource: 'countries',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    const { data: nationalities } = useQuery({
        type: 'getList',
        resource: 'nationalities',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    const validationSchema = yup.object().shape({
        username: yup.string()
            .trim()
            .required()
            .max(45),
        email: yup.string()
            .trim()
            .required()
            .email()
            .max(45),
        password: yup.string()
            .trim()
            .max(45),
        height: yup.number()
            .nullable(true)
            .min(0),
        weight: yup.number()
            .nullable(true)
            .min(0),
        rings: yup.number()
            .nullable(true)    
            .min(0),
    });

    useEffect(() => {

        const data = {
            ...user,
            gender: user.gender || ' ',
            country: user?.country?.id || null,
            nationality: user?.nationality?.id || null,
        };

        setInitialValues(data);
    }, [user]);

    const dataProvider = useDataProvider();

    const notify = useNotify();

    const onSuccess = useCallback(() => {
        notify('User update successful', 'success');
        history.push('/users');
    }, [notify, history]);

    const onFailure = useCallback((error: IMutationError) => {
        const message = extractErrorMessage(error);
        notify(message, 'error');
    }, [notify]);

    const handleSubmit = useCallback(async (values: IUser) => {

        const data = clearEmptyString(values);

        if (data.avatar && data.avatar instanceof File) {
            const formData = serialize(data);

            await apiRequestProvider({
                url: `/users/${user.id}`,
                method: 'PATCH',
                data: formData,
                onSuccess,
                onFailure,
            });
        } else {
            dataProvider.update('users', {
                id: user.id,
                data,
                previousData: user,
            }, {
                onSuccess,
                onFailure,
            });
        }

    }, [dataProvider, user, onSuccess, onFailure]);

    const handleChangeDob = useCallback((val: DateType | null, props: FormikProps<IUser>) => {

        const date = val ? val : new Date();

        props.setFieldValue('dob', date.toISOString());
    }, []);

    const handleChangeAvatar = useCallback(async (event: ChangeEvent<any>, props: FormikProps<IUser>) => {

        const file: File = event.target.files[0];

        props.setFieldValue('avatar', file);
    }, []);

    const onClickAvatar = useCallback(() => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    }, [fileInput]);

    const classes = useStyles();

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IUser>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>Edit user</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        noValidate
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} xs={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Username"
                                                        name="username"
                                                        onChange={props.handleChange}
                                                        value={props.values.username}
                                                        error={props.touched.username && Boolean(props.errors.username)}
                                                        helperText={props.touched.username && props.errors.username}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Weight"
                                                        name="weight"
                                                        onChange={props.handleChange}
                                                        value={props.values.weight}
                                                        error={props.touched.weight && Boolean(props.errors.weight)}
                                                        helperText={props.touched.weight && props.errors.weight}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl className={classes.formControl}>
                                                        {!props.values.gender && <InputLabel>Gender</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Gender"
                                                            labelId="gender-select-label"
                                                            id="gender-select"
                                                            name="gender"
                                                            value={props.values.gender}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value=" ">N/A</MenuItem>
                                                            <MenuItem value="male">Male</MenuItem>
                                                            <MenuItem value="female">Female</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl className={classes.formControl}>
                                                        {!props.values.country && <InputLabel>Country</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Country"
                                                            labelId="select-country-label"
                                                            id="country-select"
                                                            name="country"
                                                            value={props.values.country}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            {countries && countries.map((country: ICountry) => (
                                                                <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        onChange={props.handleChange}
                                                        value={props.values.password}
                                                        error={props.touched.password && Boolean(props.errors.password)}
                                                        helperText={props.touched.password && props.errors.password}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <DatePicker
                                                        fullWidth
                                                        autoOk
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label="Birthday"
                                                        disableFuture
                                                        name="dob"
                                                        value={props.values.dob ? new Date(props.values.dob).toISOString() : null}
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        onChange={(date: DateType | null) => handleChangeDob(date, props)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        label="FacebookId"
                                                        name="facebookId"
                                                        onChange={props.handleChange}
                                                        value={props.values.facebookId}
                                                        error={props.touched.facebookId && Boolean(props.errors.facebookId)}
                                                        helperText={props.touched.facebookId && props.errors.facebookId}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        label="GoogleId"
                                                        name="googleId"
                                                        onChange={props.handleChange}
                                                        value={props.values.googleId}
                                                        error={props.touched.googleId && Boolean(props.errors.googleId)}
                                                        helperText={props.touched.googleId && props.errors.googleId}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        label="appleId"
                                                        name="appleId"
                                                        onChange={props.handleChange}
                                                        value={props.values.appleId}
                                                        error={props.touched.appleId && Boolean(props.errors.appleId)}
                                                        helperText={props.touched.appleId && props.errors.appleId}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        label="rings"
                                                        name="rings"
                                                        onChange={props.handleChange}
                                                        value={props.values.rings}
                                                        error={props.touched.rings && Boolean(props.errors.rings)}
                                                        helperText={props.touched.rings && props.errors.rings}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Stats URL"
                                                        name="stats_url"
                                                        onChange={props.handleChange}
                                                        value={props.values?.stats_url||""}
                                                        error={props.touched?.stats_url && Boolean(props.errors?.stats_url)}
                                                        helperText={props.touched?.stats_url && props.errors?.stats_url}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Email"
                                                        name="email"
                                                        onChange={props.handleChange}
                                                        value={props.values.email}
                                                        error={props.touched.email && Boolean(props.errors.email)}
                                                        helperText={props.touched.email && props.errors.email}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Height"
                                                        name="height"
                                                        onChange={props.handleChange}
                                                        value={props.values.height}
                                                        error={props.touched.height && Boolean(props.errors.height)}
                                                        helperText={props.touched.height && props.errors.height}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl className={classes.formControl}>
                                                        {!props.values.role && <InputLabel>Role</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Role"
                                                            labelId="role-select-label"
                                                            id="role-select"
                                                            name="role"
                                                            value={props.values.role}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="user">User</MenuItem>
                                                            <MenuItem value="admin">Admin</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl className={classes.formControl}>
                                                        {!props.values.nationality && <InputLabel>Nationality</InputLabel>}
                                                        <Select
                                                            fullWidth
                                                            label="Nationality"
                                                            labelId="nationality-select-country"
                                                            id="nationality-select"
                                                            name="nationality"
                                                            value={props.values.nationality}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            {nationalities && nationalities.map((nationality: INationality) => (
                                                                <MenuItem key={nationality.id} value={nationality.id}>{nationality.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>

                                                <FormGroup className={classes.trigger}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={Boolean(props.values.mvp)}
                                                                onChange={props.handleChange}
                                                                color="primary"
                                                                name="mvp"
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                        }
                                                        label="MVP"
                                                    />
                                                </FormGroup>
                                                <div>
                                                    {props.values.avatar && typeof props.values.avatar === 'string' &&
                                                        <div>
                                                            <img
                                                                className={classes.avatarImg}
                                                                src={`${appConfig.apiEndpoint}/${props.values.avatar}`}
                                                            />
                                                        </div>
                                                    }

                                                    <input
                                                        className={classes.avatarBtn}
                                                        type="file"
                                                        name="avatar"
                                                        ref={fileInput}
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        onChange={(e) => handleChangeAvatar(e, props)}
                                                    />
                                                    <Button
                                                        size="large"
                                                        onClick={onClickAvatar}
                                                        color="primary"
                                                        variant="outlined"
                                                    >
                                                        Upload avatar
                                                    </Button>
                                                    <>
                                                        {props.values.avatar &&
                                                            <p
                                                                className={classes.avatarName}
                                                            >
                                                                {props.values.avatar instanceof File ? props.values.avatar.name : ''}
                                                            </p>
                                                        }
                                                    </>
                                                </div>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};