import React, { ChangeEvent, useRef, useCallback, useState, useEffect } from 'react';
import {
    IProduct,
    IProductCategory,
    IProductSize,
    IProductForm,
} from './../../interfaces/model.interfaces';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import {
    TextField,
    Button,
    Grid,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import { useNotify, useQuery } from 'react-admin';
import { appConfig } from './../../config/app.config';
import * as yup from 'yup';

export interface IProps {
    product?: IProduct;
    header: string;
    validationSchema: any;
    categories: IProductCategory[];
    sizes: IProductSize[];
    handleSubmit: (values: IProductForm) => void;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    pictureImg: {
        maxWidth: 300,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    pictureBtn: {
        display: 'none',
    },
    pictureName: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 2, 3),
    },
    header: {
        marginButton: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fileBtn: {
        display: 'none',
    },
    uploadBtn: {
        marginTop: theme.spacing(2),
    },
}));

export const ProductForm: React.FC<any> = (
    {
        product,
        header,
        handleSubmit,
    }: IProps
) => {
    
    const classes = useStyles();

    const notify = useNotify();

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [initialValues, setInitialValues] = useState<IProductForm>({
        name: '',
        price: 0,
        ringPrice: 0,
        picture: '',
        rating: 0,
        sizes: [],
        category: null,
        description: '',
    });

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .required('Name is required fields')
            .max(45),
        price: yup.number()
            .min(0)
            .required(),
        ringPrice: yup.number()
            .min(0)
            .required(),
        rating: yup.number()
            .min(0)
            .required(),
        category: yup.mixed()
            .required('Category is required field'),
        sizes: yup.array()
            .test('isChosen', 'Sizes is required fields', (data) => !!data?.length),  
    });

    const { data: categories } = useQuery({
        type: 'getList',
        resource: 'product-categories',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    const { data: sizes } = useQuery({
        type: 'getList',
        resource: 'product-sizes',
        payload: {
            pagination: {
                page: 1,
                perPage: 200,
            },
        },
    });

    const handleChangePicture = useCallback((
        event: ChangeEvent<any>,
        props: FormikProps<IProductForm>
    ) => {
        const [file] = event.target.files;

        const picture = file as File;

        const [type] = picture.type.split('/');

        if (type !== 'image') {

            notify('Uploaded file must be image', 'error');

            return false;
        }

        props.setFieldValue('picture', picture);

    }, [notify]);

    useEffect(() => {

        if (product) {
            const data = {
                ...product,
                category: product?.category?.id || null,
                sizes: product?.sizes?.map(size => size.id) || [],
            };

            setInitialValues(data);
        }

    }, [product]);

    const onChangePicture = useCallback(() => {

        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();

    }, []);

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    (props: FormikProps<IProductForm>) => (
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <h2 className={classes.header}>{header}</h2>
                                </Grid>
                                <Grid item sm={12}>
                                    <form
                                        onSubmit={props.handleSubmit}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Name"
                                                        name="name"
                                                        onChange={props.handleChange}
                                                        value={props.values.name}
                                                        error={props.touched.name && Boolean(props.errors.name)}
                                                        helperText={props.touched.name && props.errors.name}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Price"
                                                        name="price"
                                                        onChange={props.handleChange}
                                                        value={props.values.price}
                                                        error={props.touched.price && Boolean(props.errors.price)}
                                                        helperText={props.touched.price && props.errors.price}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        className={classes.formControl}
                                                        error={Boolean(props.errors.category)}
                                                    >
                                                        <InputLabel
                                                            shrink={!!props.values.category}
                                                            id="category-select-label"
                                                        >
                                                            Category
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Category"
                                                            labelId="category-select-label"
                                                            id="category-select"
                                                            name="category"
                                                            value={props.values.category}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            displayEmpty
                                                        >
                                                            {categories && categories.map((category: IProductCategory, i: number) => (
                                                                <MenuItem
                                                                    selected={category.id === props.values.category}
                                                                    key={category.id || i}
                                                                    value={category.id}
                                                                >
                                                                    {category.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {props.errors?.category &&
                                                            <FormHelperText>
                                                                {props.errors.category}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        label="Description"
                                                        name="description"
                                                        rows={3}
                                                        multiline
                                                        onChange={props.handleChange}
                                                        value={props.values.description}
                                                        error={props.touched.description && Boolean(props.errors.description)}
                                                        helperText={props.touched.description && props.errors.description}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    {props.values.picture && typeof props.values.picture === 'string' &&
                                                        <div>
                                                            <img
                                                                className={classes.pictureImg}
                                                                src={`${appConfig.apiEndpoint}/${props.values.picture}`}
                                                            />
                                                        </div>
                                                    }
                                                    <input
                                                        type="file"
                                                        name="picture"
                                                        className={classes.fileBtn}
                                                        ref={fileInput}
                                                        accept="image/*"
                                                        // eslint-disable-next-line react/jsx-no-bind
                                                        onChange={(e) => handleChangePicture(e, props)}
                                                    />
                                                    <div>
                                                        <Button
                                                            size="large"
                                                            onClick={onChangePicture}
                                                            color="primary"
                                                            variant="outlined"
                                                            className={classes.uploadBtn}
                                                        >
                                                            Upload picture
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        {props.values.picture &&
                                                            <p>
                                                                {props.values.picture instanceof File
                                                                    ? props.values.picture.name
                                                                    : ''
                                                                }
                                                            </p>
                                                        }
                                                    </div>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Rating"
                                                        name="rating"
                                                        onChange={props.handleChange}
                                                        value={props.values.rating}
                                                        error={props.touched.rating && Boolean(props.errors.rating)}
                                                        helperText={props.touched.rating && props.errors.rating}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        margin="normal"
                                                        label="Ring price"
                                                        name="ringPrice"
                                                        onChange={props.handleChange}
                                                        value={props.values.ringPrice}
                                                        error={props.touched.ringPrice && Boolean(props.errors.ringPrice)}
                                                        helperText={props.touched.ringPrice && props.errors.ringPrice}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        className={classes.formControl}
                                                        error={Boolean(props.errors.sizes)}
                                                    >
                                                        <InputLabel
                                                            shrink={!!props.values.sizes?.length}
                                                            id="size-select-label"
                                                        >
                                                            Sizes
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Sizes"
                                                            labelId="size-select-label"
                                                            id="size-select"
                                                            name="sizes"
                                                            value={props.values.sizes}
                                                            onChange={props.handleChange}
                                                            className={classes.selectEmpty}
                                                            multiple
                                                            displayEmpty
                                                        >
                                                            {sizes && sizes.map((size: IProductSize, i: number) => (
                                                                <MenuItem key={size.id || i} value={size.id}>{size.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {props.errors?.sizes &&
                                                            <FormHelperText>
                                                                {props.errors.sizes}
                                                            </FormHelperText>
                                                        }
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item md={12} sm={12}>
                                                <Button
                                                    type="submit"
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};