import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ListProps,
    Edit,
    EditButton,
} from 'react-admin';
import { EditForm } from './EditForm';


export const ConfigurationsList = (props: ListProps): JSX.Element => (
    <List
        {...props}
        title="Configurations of mvps"
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                label="MVP cost"
                source="mvpCost"
            />
            <TextField
                label="Nearest court distance"
                source="nearestCourt"
            />
            <TextField
                label="MVP discount"
                source="discount"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const ConfigurationsEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <EditForm {...props} />
    </Edit>
);