import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Create,
    TextInput,
    Filter,
    ListProps,
    Edit,
    EditButton,
} from 'react-admin';
import { CreateForm } from './CreateForm';
import { EditForm } from './EditForm';

const BannerFilter = (props: unknown): JSX.Element =>  (
    <Filter {...props}>
        <TextInput label="Id" source="id" />
        <TextInput label="Picture" source="picture" />
        <TextInput label="Created at" source="createdAt" />
    </Filter>
);

export const BannerList = (props: ListProps): JSX.Element =>(
    <List
        {...props}
        title="List of banners"
        filters={<BannerFilter />}
        sort={{
            field: 'id',
            order: 'DESC',
        }}
    >
        <Datagrid rowClick="edit">
            <TextField
                source="id"
            />
            <TextField
                source="picture"
            />
            <TextField
                source="createdAt"
            />
            <EditButton />
        </Datagrid>
    </List>
);

export const BannerCreate = (props: any): JSX.Element => (
    <Create {...props}>
        <CreateForm {...props} />
    </Create>
);

export const BannerEdit = (props: any): JSX.Element => (
    <Edit {...props}>
        <EditForm {...props} />
    </Edit>
);